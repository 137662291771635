import Link from "next/link";
import { useState } from "react";
import { MenuItemsProps, MenuItemsData } from "./Interface";
import { trackGa4Events } from "../../lib/events/ga4/tracker";
import { index } from "cheerio/lib/api/traversing";


const MenuItem: React.FC<MenuItemsProps | any> = ({ toShow, data }) => {
  const [isShowMore, SetIsShowMore] = useState(false);


  return (
    data.length && (
      <div className="flex justify-evenly px-3">
        {data
          ?.slice(0, toShow)
          .map(({ ref, title }: MenuItemsData, index: number) => (               
            <Link href={ref} key={index} passHref>
              <a  
                onClick={() => {                
                  trackGa4Events(`click-menu-top-nav{${title}}`);
                }}
              >
                <div className="py-4 px-2 mx-2 text-13 text-gray-5 font-semibold border-b-2 border-transparent cursor-pointer hover:border-black leading-6">
                  {title}
                  {/* console.log({title.replace(/\s+/g, '-').toLowerCase()}); */}
                </div>
              </a>
            </Link>
          ))}
        {data.length > toShow && (
          <div className="group">
            <div
              className=" inline-flex item-center py-4 p-2 text-gray-5 text-13 font-semibold border-b-2 border-transparent cursor-pointer group-hover:border-black leading-6"
              onMouseEnter={() => SetIsShowMore(true)}
              onClick={() => {                
                trackGa4Events('click-more-category-top-nav');
              }}
            >
              More{" "}
              <div className="pl-1 -mt-1p">
                <i className="blog-header-arrow-down-icon inline-block"></i>
              </div>
            </div>
            <div className="absolute z-50 flex-col hidden overflow-y-auto bg-white rounded-sm shadow-md w-52 max-h-60 group-hover:block customized-scrollbar">
              {isShowMore &&
                data
                  ?.slice(toShow)
                  .map(({ ref, title }: MenuItemsData, index: number) => (
                    <Link href={ref} key={index} passHref>
                      <a
                        onClick={() => {
                          trackGa4Events(`click-menu-top-nav{${title}}`);
                        }}
                      >
                        <div className="py-2 px-3 border-b border-gray-100 text-13 cursor-pointer hover:bg-gray-100">
                          {title}                          
                        </div>
                      </a>
                    </Link>
                  ))}
            </div>
          </div>
        )}
      </div>
    )
  );
};
export default MenuItem;
