import camelcase from "camelcase";
export function camelCaseConvertor(data: any) {
  let result: any = [];

  Object.keys(data).forEach((key) => {
    let camelCaseKey = camelcase(key);

    result[camelCaseKey] = data[key];
  });

  return result;
}
