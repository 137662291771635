import publicIp from 'public-ip';
import { setLocation } from '../apis/location';

// getUserLocationData
export async function getUserLocationData() {
  let getUserLocationCookieResponse = getUserLocationCookieData('user-location');

  if (getUserLocationCookieResponse) {
    return JSON.parse(getUserLocationCookieResponse);
  } else {

    //const ipAddress = await publicIp.v4();

    // setLocation
    const responseData = await setLocation('ip', '', '');
    const domainName = (window.location.hostname === 'dev-website-blog.knowledgehut.dev') ? window.location.hostname : '.knowledgehut.com';

    document.cookie = `${'user-location'}=${JSON.stringify(responseData)}; max-age=31556952;path=/;domain=${domainName};`;

    return responseData;
  }
}

// getUserLocationCookieData
export const getUserLocationCookieData = (name: string) => {
  if (typeof document === 'undefined') {
    return '';
  }
  const cookies = document.cookie.split(';');

  let value: string = '';
  cookies.forEach((cookie) => {
    if (cookie.trim().startsWith(name)) {
      value = cookie.replace(`${name}=`, '');
    }
  });

  return value;
};
