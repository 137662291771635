export const menuItemDetails: Array<any> = [
  {
    ref: "/blog/category/agile",
    title: "Agile",
  },
  {
    ref: "/blog/category/project-management",
    title: "Project Management",
  },
  {
    ref: "/blog/category/data-science",
    title: "Data Science",
  },
  {
    ref: "/blog/category/programming",
    title: "Programming",
  },
  {
    ref: "/blog/category/devops",
    title: "DevOps",
  },
  {
    ref: "/blog/category/it-service-management",
    title: "IT Service Management",
  },
  {
    ref: "/blog/category/information-technology",
    title: "Information Technology",
  },
  {
    ref: "/blog/category/business-management",
    title: "Business Management",
  },
  {
    ref: "/blog/category/big-data",
    title: "Big Data",
  },
  {
    ref: "/blog/category/blockchain",
    title: "Blockchain",
  },
  {
    ref: "/blog/category/cloud-computing",
    title: "Cloud Computing",
  },
  {
    ref: "/blog/category/learning",
    title: "Learning",
  },
  {
    ref: "/blog/category/security",
    title: "Security",
  },
  {
    ref: "/blog/category/web-development",
    title: "Web Development",
  },
  {
    ref: "/blog/category/finance",
    title: "Finance",
  },
  {
    ref: "/blog/category/digital-marketing",
    title: "Digital Marketing",
  },
  {
    ref: "/blog/category/quality",
    title: "Quality",
  },
  {
    ref: "/blog/category/business-intelligence-and-visualization",
    title: "Business intelligence and Visualization",
  },
  {
    ref: "/blog/category/career",
    title: "Career",
  },
  {
    ref: "/blog/category/journal",
    title: "Journal",
  },
  {
    ref: "/blog/category/others",
    title: "Others",
  },
  {
    ref: "/blog/category/software-testing",
    title: "Software Testing",
  },
  /* {
    ref: "/blog/type/blogs",
    title: "Blogs",
  },
  {
    ref: "/blog/type/infographics",
    title: "Infographics",
  },
  {
    ref: "/blog/type/news",
    title: "news",
  },
  {
    ref: "/blog/type/announcements",
    title: "Announcements",
  }, */
];
