import { tracker } from "./tracker";

function trackEvent(event: string, eventData = {}) {
  try {
    tracker({
      event,
      data: {
        page_category: "kh_blog_page",
        section: "",
        component_name: "",
        label: "",
        email_id: "NA",
        ...eventData,
      },
    });
  } catch (error) {
    console.error(`Error tracking ${event} event:`, error);
  }
}

export function trackPageLoaded() {
  trackEvent("page_loaded");
}

export function trackPopupClosed() {
  trackEvent("popup_closed", {
    section: "sticky_chat_bot",
    component_name: "hello_pop_up",
  });
}

export function trackPhoneIconClick() {
  trackEvent("cta_click", {
    section: "sticky_chat_bot",
    component_name: "call_us",
  });
}

export function trackChatIconClick() {
  trackEvent("cta_click", {
    section: "sticky_chat_bot",
  });
}

export function trackChatExpensionClick(comp: string) {
  trackEvent("expansion_click", {
    section: "sticky_chat_bot",
    component_name: comp,
  });
}

export function trackWhatsappClick() {
  trackEvent("cta_click", {
    section: "sticky_chat_bot",
    component_name: "whatsapp",
  });
}

export function trackHandleChatbotClose() {
  trackEvent("modal_close", {
    section: "sticky_chat_bot",
  });
}
