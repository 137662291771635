import Link from "next/link";
import React from "react";
import TopHeaderMenuItems from "../TopHeaderMenuItems";
import { EnterpriseData,BootcampsData, ResourcesData } from "../TopHeaderMenuItems/Data";
import { trackGa4Events } from "../../lib/events/ga4/tracker";
interface Props {
  phoneNumber?: string;
}

const HeaderTop: React.FC<Props> = ({ phoneNumber }) => {
  return (
    <section className=" h-6 w-full bg-gray-14">
      <div
        className={` flex items-center gap-2 container ${
          phoneNumber ? "justify-between" : "justify-end"
        }`}
      >
        {phoneNumber && (
          <div className=" hidden md:flex items-center gap-2">
            <p className="text-gray-5 text-xs leading-[15px] font-medium">
              For enquiries call:
            </p>
            <div className="flex gap-1 items-center">
              <img
                src="https://d2o2utebsixu4k.cloudfront.net/phone_black.svg"
                width={1}
                height={1}
                className="w-4 h-4"
              />
              <p className="text-black text-xs leading-[15px] font-bold">
                {phoneNumber}
              </p>
            </div>
          </div>
        )}
     <div className="flex gap-2 items-center">
     <Link href='/courses'>
       <a >
          <div className="pr-4 -mt-1p text-gray-5 text-xs font-normal cursor-pointer" onClick={() =>{trackGa4Events('click-header-all-courses');}}>
            All Courses
          </div>
        </a></Link>
        {/* Menu items */}
        <TopHeaderMenuItems toShow={0} title="Bootcamps" data={BootcampsData} />

        {/* Menu items */}
        <TopHeaderMenuItems toShow={0} title="Enterprise" data={EnterpriseData} />

        {/* Menu items */}
        <TopHeaderMenuItems toShow={0} title="Resources" data={ResourcesData} />
        
     </div>
      </div>
    </section>
  );
};

export default HeaderTop;
