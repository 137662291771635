export const ga4Events : any = {
    // events in desktop events
    'click-all-blogs-top-nav': { //done
        label: 'All blogs',
        name: 'Click_All blogs_Top Nav',
        short_name: 'cli_allblo_topnav',
        action: 'Click',
        page_section : 'Top Nav'
    },
    'click-agile-top-nav': {
        label: 'Agile',
        name: 'Click_Agile_Top Nav',
        short_name: 'cli_agi_topnav',
        action: 'Click',
        page_section : 'Top Nav'
    },
    'click-project-management-top-nav': {
        label: 'Project management',
        name: 'Click_Project management_Top Nav',
        short_name: 'cli_proman_topnav',
        action: 'Click',
        page_section : 'Top Nav'
    },
    'click-data-science-top-nav': {
        label: 'Data science',
        name: 'Click_All blogs_Top Nav',
        short_name: 'cli_datsci_topnav',
        action: 'Click',
        page_section : 'Top Nav'
    },
    'click-more-category-top-nav': {
        label: 'More_Category',
        name: 'Click_More_Category_Top Nav',
        short_name: 'cli_mor_cat_topnav',
        action: 'Click',
        page_section : 'Top Nav'
    },
    'click-subscribe-top-nav': { //done
        label: 'Subscribe',
        name: 'Click _Subscribe_Top Nav',
        short_name: 'cli_sub_topnav',
        action: 'Click',
        page_section : 'Top Nav'
    },
    'submit-subscribe-top-nav': {
        label: 'Subscribe',
        name: 'Submit _Subscribe_Top Nav',
        short_name: 'sub_sub_topnav',
        action: 'Submit',
        page_section : 'Top Nav'
    },
    'click-search-featured-blog-posts': {
        label: 'Search',
        name: 'Click _Search _Featured blog posts',
        short_name: 'cli_sea_feablopos',
        action: 'Click',
        page_section : 'Featured blog posts'
    },
    'click-case-study-featured-blog-posts': {
        label: 'Case study',
        name: 'Click_Case study_Featured blog posts',
        short_name: 'cli_casstu_feablopos',
        action: 'Click',
        page_section : 'Featured blog posts'
    },
    'click-trending-posts-blog-trending-posts': {
        label: 'Trending posts_Blog',
        name: 'Click_Trending posts_Blog_Trending posts',
        short_name: 'cli_trepos_blo_trepos',
        action: 'Click',
        page_section : 'Trending posts',
        extras:{
            blog_name : '{value}'
        }
    },
    'click-trending-posts-category-tag-trending-posts': {
        label: 'Trending posts_Category tag',
        name: 'Click_Trending posts_Category tag_Trending posts',
        short_name: 'cli_trepos_cattag_trepos',
        action: 'Click',
        page_section : 'Trending posts'
        
    },
    'click-trending-posts-category-trending-posts': {
        label: 'Trending posts_{value}',
        name: 'Click_Trending posts_{value}_Trending posts',
        short_name: 'cli_trepos_{value}_trepos',
        action: 'Click',
        page_section : 'Trending posts Category'        
    },
    'click-menu-top-nav': {
        label: '{value}',
        name: 'Click_{value}_Top Nav',
        short_name: 'cli_{value}_topnav',
        action: 'Click',
        page_section : 'Top Nav'        
    },
    'click-topics-blogs-topics': {
        label: 'Topics_Blogs',
        name: 'Click_Topics_Blogs_Topics',
        short_name: 'cli_top_blo_top',
        action: 'Click',
        page_section : 'Topics'
    },
    'click-topics-infographics-topics': {
        label: 'Topics_Infographics',
        name: 'Click_Topics_Infographics_Topics',
        short_name: 'cli_top_inf_top',
        action: 'Click',
        page_section : 'Topics'
    },
    'click-topics-news-topics': {
        label: 'Topics_News',
        name: 'Click_Topics_News_Topics',
        short_name: 'cli_top_new_top',
        action: 'Click',
        page_section : 'Topics'
    },
    'click-topics-announcements-topics': {
        label: 'Topics_Announcements',
        name: 'Click_Topics_Announcements_Topics',
        short_name: 'cli_top_ann_top',
        action: 'Click',
        page_section : 'Topics'
    },
    'click-latest-posts-latest-posts': {
        label: 'Latest posts',
        name: 'Click_Latest posts',
        short_name: 'cli_latpos_latpos',
        action: 'Click',
        page_section : 'Latest posts',
        extras:{
            blog_name : '{value}'
        }
    },
    'click-latest-posts-author': {
        label: 'Author',
        name: 'Click_Author_{value}_Latest posts',
        short_name: 'cli_aut_{value}_latpos',
        action: 'Click',
        page_section : 'Latest posts'
    },
    'click-latest-posts-category-latest-posts': {
        label: 'Latest posts_{value}',
        name: 'Click_Latest posts_{value}_Latest posts',
        short_name: 'cli_latpos_{value}_latpos',
        action: 'Click',
        page_section : 'Latest posts'        
    },   
    'click-latest-posts-load-more-latest-posts': {
        label: 'Latest posts_Load more',
        name: 'Click_Latest posts_Load more_Latest posts',
        short_name: 'cli_latpos_loamor_latpos',
        action: 'Click',
        page_section : 'Latest posts'
    },
    'click-blog-view-load-more-suggested-blogs': {
        label: 'Blog View_Load more',
        name: 'Click_Blog View_Load more_suggested blogs',
        short_name: 'cli_blovie_loamor_sugblo',
        action: 'Click',
        page_section : 'Suggested Blogs'
    },
    'click-category-list-load-more-latest-posts': {
        label: 'Category List_Load more',
        name: 'Click_Category List_Load more_Latest posts',
        short_name: 'cli_catlis_loamor_latpos',
        action: 'Click',
        page_section : 'Category Latest posts'
    },
    'click-topics-list-load-more-latest-posts': { //topics
        label: 'Topics_Category List_Load more',  
        name: 'Click_Topics List_Load more_Latest posts',
        short_name: 'cli_toplis_loamor_latpos',
        action: 'Click',
        page_section : 'Topics Latest posts'
    },
    'click-chat-assistant-blog-sticky-nav': {
        label: 'Chat assistant',
        name: 'Click_Chat assistant_Blog_Sticky Nav',
        short_name: 'cli_chaass_blo_stinav',
        action: 'Click',
        page_section : 'Blog_Sticky Nav'
    },
    'submit-weekly-newsletter-bottom-nav': {
        label: 'Weekly Newsletter',
        name: 'Submit_Weekly Newsletter_Bottom Nav',
        short_name: 'sub_weenew_botnav',
        action: 'Submit',
        page_section : 'Bottom Nav'
    },
    'Submit_Talk to Us_RHS Blog Sticky': {
        label: 'Talk to Us',
        name: 'Submit_Talk to Us_RHS Blog Sticky',
        short_name: 'sub_taltous_rhsblosti',
        action: 'Submit',
        page_section : 'RHS Blog Sticky'
    },

    // events in desktop events ends

    //resourse events starts
    'submit-submit-exit-popup-schedule-1:1-call-v1': {
        label: 'Submit',
        name: 'Submit_submit_exit popup schedule 1:1 call_v1',
        short_name: 'sub_sub_exipopsch1:1call_v1',
        action: 'Submit',
        page_section : 'exit popup schedule 1:1 call_v1'
    },
    'click-submit-otp-exit-popup-schedule-1:1-call-v1': {
        label: 'Submit otp',
        name: 'Click_submit otp_exit popup schedule 1:1 call_v1',
        short_name: 'cli_subotp_exipopsch1:1call_v1',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v1 '
    },
    'click-resent-code-exit-popup-schedule-1:1-call-v1': {
        label: 'resent code',
        name: 'Click_resent code_exit popup schedule 1:1 call_v1',
        short_name: 'cli_rescod_exipopsch1:1call_v1',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v1 '
    },
    'click-explore-courses-exit-popup-schedule-1:1-call-v1': {
        label: 'explore courses',
        name: 'Click_explore courses_exit popup schedule 1:1 call_v1',
        short_name: 'cli_expcou_exipopsch1:1call_v1',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v1'
    },
    'click-refer-and-earn-exit-popup-schedule-1:1-call-v1': {
        label: 'Refer and Earn',
        name: 'Click_Refer and Earn_exit popup schedule 1:1 call_v1 ',
        short_name: 'cli_refandear_exipopsch1:1call_v1',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v1'
    },
    'click-prism-video-lhs-sticky': {
        label: 'prism video',
        name: 'Click_prism video_lhs sticky',
        short_name: 'cli_privid_lhssti',
        action: 'Click',
        page_section : 'lhs sticky'
    },
    'click-play-prism-video-lhs-sticky': {
        label: 'play prism video',
        name: 'Click_play prism video_lhs sticky',
        short_name: 'cli_plyprivid_lhssti',
        action: 'Click',
        page_section : 'lhs sticky'
    },
    'click-explore-courses-lhs-sticky': {
        label: 'explore courses prism video',
        name: 'Click_explore courses_lhs sticky',
        short_name: 'cli_expcouprivid_lhssti',
        action: 'Click',
        page_section : 'lhs sticky'
    },
    'submit-submit-exit-popup-schedule-1:1-call-v2': {
        label: 'Submit',
        name: 'Submit_submit_exit popup schedule 1:1 call_v2',
        short_name: 'sub_sub_exipopsch1:1call_v2',
        action: 'Submit',
        page_section : 'exit popup schedule 1:1 call_v2'
    },
    'click-submit-otp-exit-popup-schedule-1:1-call-v2': {
        label: 'submit otp',
        name: 'Click_submit otp_exit popup schedule 1:1 call_v2 ',
        short_name: 'cli_subotp_exipopsch1:1call_v2',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v2'
    },
    'click-resent-code-exit-popup-schedule-1:1-call-v2': {
        label: 'resent code',
        name: 'Click_resent code_exit popup schedule 1:1 call_v2',
        short_name: 'cli_rescod_exipopsch1:1call_v2',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v2'
    },
    'click-select-course-exit-popup-schedule-1:1-call': {
        label: 'select course',
        name: 'Click_select course_exit popup schedule 1:1 call_{value}',
        short_name: 'cli_selcou_exipopsch1:1call_{value}',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_{value}'
    },
    'submit-book-a-call-exit-popup-schedule-1:1-call-v2': {
        label: 'book a call',
        name: 'submit_book a call_exit popup schedule 1:1 call_v2 ',
        short_name: 'sub_booacal_exipopsch1:1call_v2',
        action: 'Submit',
        page_section : 'exit popup schedule 1:1 call_v2'
    },
    'click-skip-exit-popup-schedule-1:1-call-v2': {
        label: 'skip',
        name: 'Click_skip_exit popup schedule 1:1 call_v2',
        short_name: 'cli_ski_exipopsch1:1call_v2',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v2'
    },
    'click-explore-courses-exit-popup-schedule-1:1-call-v2': {
        label: 'explore courses',
        name: 'Click_explore courses_exit popup schedule 1:1 call_v2',
        short_name: 'cli_expcou_exipopsch1:1call_v2',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v2'
    },
    'click-refer-and-earn-exit-popup-schedule-1:1-call-v2': {
        label: 'Refer and Earn',
        name: 'Click_Refer and Earn_exit popup schedule 1:1 call_v2 ',
        short_name: 'cli_refandear_exipopsch1:1call_v2',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v2'
    },
    'click-select-course-exit-popup-schedule-1:1-call-v3': {
        label: 'select course',
        name: 'click_select course_exit popup schedule 1:1 call_v3',
        short_name: 'cli_selcou_exipopsch1:1call_v3',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v2'
    },
    'submit-submit-exit-popup-schedule-1:1-call-v3': {
        label: 'Submit',
        name: 'Submit_submit_exit popup schedule 1:1 call_v3',
        short_name: 'sub_sub_exipopsch1:1call_v3',
        action: 'Submit',
        page_section : 'exit popup schedule 1:1 call_v3'
    },
    'click-submit-otp-exit-popup-schedule-1:1-call-v3': {
        label: 'Submit OTP',
        name: 'Click_submit otp_exit popup schedule 1:1 call_v3',
        short_name: 'cli_subotp_exipopsch1:1call_v3',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v3'
    },
    'click-resent-code-exit-popup-schedule-1:1-call-v3': {
        label: 'resent code',
        name: 'Click_resent code_exit popup schedule 1:1 call_v3',
        short_name: 'cli_rescod_exipopsch1:1call_v3',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v3'
    },
    'click-go-to-course-exit-popup-schedule-1:1-call-v3': {
        label: 'go to course',
        name: 'Click_go to course_exit popup schedule 1:1 call_v3',
        short_name: 'cli_gotocou_exipopsch1:1call_v3',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v3'
    },
    'click-refer-and-earn-exit-popup-schedule-1:1-call-v3': {
        label: 'Refer and Earn',
        name: 'Click_Refer and Earn_exit popup schedule 1:1 call_v3',
        short_name: 'cli_refandear_exipopsch1:1call_v3',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v3'
    },
    'click-choose-slot-exit-popup-schedule-1:1-call-v4': {
        label: 'choose slot',
        name: 'click_choose slot_exit popup schedule 1:1 call_v4',
        short_name: 'cli_choslo_exipopsch1:1call_v4',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'submit-register-now-exit-popup-schedule-1:1-call-v4': {
        label: 'register now',
        name: 'Submit_register now_exit popup schedule 1:1 call_v4',
        short_name: 'sub_regnow_exipopsch1:1call_v4',
        action: 'Submit',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'click-skip-exit-popup-schedule-1:1-call-v4': {
        label: 'skip',
        name: 'Click_skip_exit popup schedule 1:1 call_v4',
        short_name: 'cli_ski_exipopsch1:1call_v4',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'click-get-coupon-code-otp-exit-popup-schedule-1:1-call-v4': {
        label: 'get coupon code otp',
        name: 'Click_get coupon code otp_exit popup schedule 1:1 call_v4',
        short_name: 'cli_getcoucodotp_exipopsch1:1call_v4',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'click-resent-code-exit-popup-schedule-1:1-call-v4': {
        label: 'resent code',
        name: 'Click_resent code_exit popup schedule 1:1 call_v4',
        short_name: 'cli_rescod_exipopsch1:1call_v4',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'click-go-to-course-exit-popup-schedule-1:1-call-v4': {
        label: 'go to course',
        name: 'Click_go to course_exit popup schedule 1:1 call_v4',
        short_name: 'cli_gotocou_exipopsch1:1call_v4',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'click-refer-and-earn-exit-popup-schedule-1:1-call-v4': {
        label: 'Refer and Earn',
        name: 'Click_Refer and Earn_exit popup schedule 1:1 call_v4',
        short_name: 'cli_refandear_exipopsch1:1call_v4',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'click-copy-link-exit-popup-schedule-1:1-call-v3': {
        label: 'copy link',
        name: 'Click_copy link_exit popup schedule 1:1 call_v3',
        short_name: 'cli_coplin_exipopsch1:1call_v3',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v3'
    },
    'click-copy-link-exit-popup-schedule-1:1-call-v4': {
        label: 'copy link',
        name: 'Click_copy link_exit popup schedule 1:1 call_v4',
        short_name: 'cli_coplin_exipopsch1:1call_v4',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'submit-submit-exit-popup-schedule-1:1-call-v4': {
        label: 'Submit',
        name: 'Submit_submit_exit popup schedule 1:1 call_v4',
        short_name: 'sub_sub_exipopsch1:1call_v4',
        action: 'Submit',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'click-submit-otp-exit-popup-schedule-1:1-call-v4': {
        label: 'Submit OTP',
        name: 'Click_submit otp_exit popup schedule 1:1 call_v4',
        short_name: 'cli_subotp_exipopsch1:1call_v4',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'submit-book-a-call-exit-popup-schedule-1:1-call-v4': {
        label: 'book a call',
        name: 'submit_book a call_exit popup schedule 1:1 call_v4',
        short_name: 'sub_booacal_exipopsch1:1call_v4',
        action: 'Submit',
        page_section : 'exit popup schedule 1:1 call_v4'
    },
    'click-social-icon-share-this-article': {
        label: '{value} Social icon',
        name: 'Click_{value} icon_share this article',
        short_name: 'cli_{value}ico_shathiart',
        action: 'Click',
        page_section : 'share this article',
    },   
    'hover-share-blog-header-section': {
        label: 'Social Sharing',
        name: 'mouse hover_share_blog header section',
        short_name: 'mouhov_sha_bloheasec',
        action: 'Mouse Hover',
        page_section : 'Blog header section'
    },  
    'click-social-icon-blog-header-section': {
        label: '{value} Social icon',
        name: 'Click_{value} icon_blog header section',
        short_name: 'cli_{value}ico_bloheasec',
        action: 'Click',
        page_section : 'blog header section',
    },    
    'submit-download-brochure-certification': {
        label: 'Download Brochure',
        name: 'Submit_Download Brochure_Certification',
        short_name: 'sub_dowbro_cer',
        action: 'Submit',
        page_section : 'Certification'
    }, 
    'click-close-x-exit-popup-download-free-guide-v5': {
        label: 'close x',
        name: 'click_close x_exit popup download free guide_v5',
        short_name: 'cli_clox_exipopdowfregui_v5',
        action: 'Click',
        page_section : 'exit popup download free guide_v5'
    },
    'click-whatsapp-consent-check-exit-popup-download-free-guide-v5': {
        label: 'whatsapp consent check',
        name: 'click_whatsapp consent check_exit popup download free guide_v5',
        short_name: 'cli_whaconche_exipopdowfregui_v5',
        action: 'Click',
        page_section : 'exit popup download free guide_v5'
    },
    'click-go-to-course-exit-popup-download-free-guide-v5': {
        label: 'go to course',
        name: 'click_go to course_exit popup download free guide_v5',
        short_name: 'cli_gotocou_exipopdowfregui_v5',
        action: 'Click',
        page_section : 'exit popup download free guide_v5'
    },
    'submit-download-free-guide-exit-popup-download-free-guide-v5': {
        label: 'download free guide',
        name: 'Submit_download free guide_exit popup download free guide_v5',
        short_name: 'sub_dowfregui_exipopdowfregui_v5',
        action: 'Submit',
        page_section : 'exit popup download free guide_v5'
    }, 
    'click-submit-otp-exit-popup-download-free-guide-v5': {
        label: 'submit otp',
        name: 'click_submit otp_exit popup download free guide_v5',
        short_name: 'cli_subotp_exipopdowfregui_v5',
        action: 'Click',
        page_section : 'exit popup download free guide_v5'
    },
    'click-resent-code-exit-popup-download-free-guide-v5': {
        label: 'resent code',
        name: 'click_resent code_exit popup download free guide_v5',
        short_name: 'cli_rescod_exipopdowfregui_v5',
        action: 'Click',
        page_section : 'exit popup download free guide_v5'
    },
    'click-refer-and-earn-exit-popup-download-free-guide-v5': {
        label: 'Refer and Earn',
        name: 'click_Refer and Earn_exit popup download free guide_v5',
        short_name: 'cli_refandear_exipopdowfregui_v5',
        action: 'Click',
        page_section : 'exit popup download free guide_v5'
    },
    'click-bottom-sticky-form-bottom-sticky': {
        label: 'Bottom sticky form',
        name: 'Click_Bottom sticky form_Bottom sticky',
        short_name: 'cli_botstifor_botsti',
        action: 'Click',
        page_section : 'Bottom sticky'
    },
    'submit-bottom-sticky-form-bottom-sticky': {
        label: 'Bottom sticky form',
        name: 'Submit_Bottom sticky form_Bottom sticky',
        short_name: 'sub_botstifor_botsti',
        action: 'Submit',
        page_section : 'Bottom sticky'
    }, 
    'click-blog-view-search-banner': {
        label: 'Blog View_search',
        name: 'Click_Blog View_search_Banner',
        short_name: 'cli_blovie_sea_ban',
        action: 'Click',
        page_section : 'Banner'
    },
    'submit-download-ebook-banner-image': {
        label: 'Download Ebook',
        name: 'Submit_Download Ebook_Banner image',
        short_name: 'sub_dowebo_banimg',
        action: 'Submit',
        page_section : 'Banner image'
    }, 
    'submit-preview-video-banner-image': {
        label: 'Preview Video',
        name: 'Submit_Preview Video_Banner image',
        short_name: 'sub_previd_banimg',
        action: 'Submit',
        page_section : 'Banner image'
    }, 
    'submit-submit-exit-popup-schedule-1:1-call-v0': {
        label: 'submit',
        name: 'Submit_submit_exit popup schedule 1:1 call_v0',
        short_name: 'sub_sub_exipopsch1:1call_v0',
        action: 'Submit',
        page_section : 'exit popup schedule 1:1 call_v0'
    }, 
    'click-refer-and-earn-exit-popup-schedule-1:1-call-v0': {
        label: 'Refer and Earn',
        name: 'Click_Refer and Earn_exit popup schedule 1:1 call_v0',
        short_name: 'cli_refandear_exipopsch1:1call_v0',
        action: 'Click',
        page_section : 'exit popup schedule 1:1 call_v0'
    }, 
    'click-join-whatsapp-group-success-popup': {
        label: 'Join Whatsapp Group',
        name: 'Click_Join Whatsapp Group_Success Popup',
        short_name: 'cli_joiwhagro_sucpop',
        action: 'Click',
        page_section : 'Success Popup'
    },
    'click-header-all-courses': {
        label: 'Courses',
        name: 'Hover_Courses _Header',
        short_name: 'cli_cou_hea',
        action: 'Click',
        page_section : 'Header'
    },
    'hover-header-menu': {
        label: '{value}',
        name: 'Hover_{value} _Header',
        short_name: 'hov_{value}_hea',
        action: 'Hover',
        page_section : 'Header'
    },
    'hover-header-enterprise': {
        label: 'Enterprice',
        name: 'Hover_Enterprice _Header',
        short_name: 'hov_ent_hea',
        action: 'Hover',
        page_section : 'Header'
    },
    'hover-header-resources': {
        label: 'Resources',
        name: 'Hover_Resources _Header',
        short_name: 'hov_res_hea',
        action: 'Hover',
        page_section : 'Header'
    },
    'homepage_click_Khut_logo_header': {
        label: 'Khut logo',
        name: 'Click _Khut logo_Header',
        short_name: 'cli_khulog_hea',
        action: 'Click',
        page_section : 'Header'
    },
    "mega_menu_click_menu_items" : {
        label : "{value} Category",
        name : "Click_{value} Category_Courses Navigation",
        short_name : "cli_{value}cat_counav",
        action : "Click",
        page_section : "Courses Navigation"
      },
      "mega_menu_click_course_menu_items" : {
        label : "Course",
        name : "Click_Course_Courses Navigation",
        short_name : "cli_cou_counav",
        action : "Click",
        page_section : "Courses Navigation",
        parameters : {
            course : "{value}"
        }
      },
      "mega_menu_click_view_all_courses" : {
        label : "View all courses",
        name : "Click _View all courses_Header",
        short_name : "cli_vieallcou_hea",
        action : "Click",
        page_section : "Header"
      },
};