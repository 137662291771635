import axios from "axios";
import { ICountry } from "../../Interfaces/country";
import { camelCaseConvertor } from "./camelCaseConvertor";

// getCountryByBName
export async function getCountryByBName(name: string) {
  const countryData = await axios.get(`kh/countries/`, {
    params: { name: name},
    validateStatus: () => true,
  });

  if (countryData.data.result == false) return null;

  return countryData.data.result ? camelCaseConvertor(countryData.data.result[0]) : null;
}

// getDefaultCityByCountryID
export async function getDefaultCityByCountryID(id: number, status?: number) {
  const countryDefaultCityData = await axios.get(`kh/countries/${id}/default_city`, {
    params: { status: status},
    validateStatus: () => true,
  });

  if (countryDefaultCityData.data.result == false) return null;

  return countryDefaultCityData.data.result ? countryDefaultCityData.data.result[0] : null;
}

// getCitiesByCountryID
export async function getCitiesByCountryID(id: number) {
  const countryCitiesList = await axios.get(`kh/cities/`, {
    params: { country_id: id, status: 1},
    validateStatus: () => true,
  });

  if (countryCitiesList.data.result == false) return [];

  let cities: { id: any; name: any; }[] = [];

  countryCitiesList.data.result.map((data: { id: any; name: any; }) => {
    cities.push({ id: data.id, name: data.name });
  });

  return cities;
}

export async function getCountryByCode(code: string | string[]) {
  if(typeof code === 'string')
  {
    const countryData = await axios.get(`kh/countries/`, {
      params: { code: code},
      validateStatus: () => true,
    });
  
    if (countryData.data.result == false) return null;
  
    return countryData.data.result ? countryData.data.result[0] : null;
  }else{
    return;
  }
}
