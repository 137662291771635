// storeDataToLocal
const storeDataToLocal = (data: any) => {
  delete data.certifying_authority;
  delete data.message;
  delete data.europe_gdpr_consent;
  delete data.whatsapp_subscription_status;

  const prevStoredData = { ...data };

  localStorage.setItem("blogFormData", JSON.stringify(prevStoredData));
};

export default storeDataToLocal;
