import { PAGE_CATEGORY, PAGE_TYPES } from "../constants";
// getFormatDateForSnippet
export const getFormatDateForSnippet = (
  startDate: string,
  endDate: string,
  bYear: boolean
) => {
  let startDateF = new Date(startDate);
  let endDateF = new Date(endDate);
  const currenctDate = new Date();
  let strDate = "";

  // Check same year
  if (startDateF.getFullYear() == endDateF.getFullYear()) {
    // Check same month
    if (startDateF.getMonth() == endDateF.getMonth()) {
      // Check same day
      if (startDateF.getDate() == endDateF.getDate()) {
        // Check year is equal to current year
        strDate =
          startDateF.getFullYear() == currenctDate.getFullYear() && !bYear
            ? startDateF.toLocaleDateString("en-US", {
                month: "short",
              }) +
              " " +
              startDateF.getDate()
            : startDateF.toLocaleDateString("en-US", {
                month: "short",
              }) +
              " " +
              ("0" + startDateF.getDate()).slice(-2) +
              ", " +
              startDateF.getFullYear();
      } // Days are not equal
      else {
        // Check year is equal to current year
        strDate =
          startDateF.getFullYear() == currenctDate.getFullYear() && !bYear
            ? startDateF.toLocaleDateString("en-US", { month: "short" }) +
              " " +
              ("0" + startDateF.getDate()).slice(-2) +
              " - " +
              endDateF.toLocaleDateString("en-US", { month: "short" }) +
              " " +
              ("0" + endDateF.getDate()).slice(-2)
            : startDateF.toLocaleDateString("en-US", { month: "short" }) +
              " " +
              ("0" + startDateF.getDate()).slice(-2) +
              " - " +
              endDateF.toLocaleDateString("en-US", { month: "short" }) +
              " " +
              ("0" + endDateF.getDate()).slice(-2) +
              ", " +
              endDateF.getFullYear();
      }
    } // Months are not equal
    else {
      // Check year is equal to current year
      strDate =
        startDateF.getFullYear() == currenctDate.getFullYear() && !bYear
          ? startDateF.toLocaleDateString("en-US", { month: "short" }) +
            " " +
            ("0" + startDateF.getDate()).slice(-2) +
            " - " +
            endDateF.toLocaleDateString("en-US", { month: "short" }) +
            " " +
            ("0" + endDateF.getDate()).slice(-2)
          : startDateF.toLocaleDateString("en-US", { month: "short" }) +
            " " +
            ("0" + startDateF.getDate()).slice(-2) +
            " - " +
            endDateF.toLocaleDateString("en-US", { month: "short" }) +
            " " +
            ("0" + endDateF.getDate()).slice(-2) +
            " " +
            endDateF.getFullYear();
    }
  } // Years are not equal
  else {
    strDate =
      startDateF.toLocaleDateString("en-US", { month: "short" }) +
      " " +
      ("0" + startDateF.getDate()).slice(-2) +
      ", " +
      startDateF.getFullYear() +
      " - " +
      endDateF.toLocaleDateString("en-US", { month: "short" }) +
      " " +
      ("0" + endDateF.getDate()).slice(-2) +
      ", " +
      endDateF.getFullYear();
  }

  return strDate;
};

// stripTags
export const stripTags = (str: string) => {
  return str.replace(/(<([^>]+)>)/gi, "");
};

// randomValue
export const randomValue = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// getFileSize
export const getFileSize = (url: string | URL) => {
  let fileSize;
  const http = new XMLHttpRequest();
  http.open("HEAD", url, false); // false = Synchronous

  http.send(null); // it will stop here until this http request is complete

  if (http.status === 200) {
    fileSize = http.getResponseHeader("content-length");
  } else {
    fileSize = 0;
  }

  return fileSize;
};

// currencyFormatter
export const currencyFormatter = (props: {
  locale: string;
  currency: string;
  price: number;
}) => {
  let { locale, currency, price } = props;
  try {
    var formattedPrice = new Intl.NumberFormat(locale, {
      currency: currency,
    }).format(price);
  } catch (e) {
    console.error("currency Formateer issue" + e);
    return price;
  }
  return formattedPrice;
};

// currencyFormatterWithSymbol
export const currencyFormatterWithSymbol = (props: {
  locale: string;
  currency: string;
  price: number;
}) => {
  let { locale, currency, price } = props;
  try {
    var formattedPrice = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
    }).format(price);
  } catch (e) {
    console.error("currency Formateer issue" + e);
    return price;
  }
  return formattedPrice;
};

export const getSecondsFromHours = (valueInHours: number) => {
  return valueInHours * 60 * 60;
};

export const getPageType = (router: any) => {
  if (!router.query.slug) {
    return PAGE_TYPES.HOME;
  } else if (router.pathname.includes("category") && !router.pathname.includes("[category]") ) {
    return PAGE_TYPES.CATEGORY;
  } else if (router.pathname.includes("author")) {
    return PAGE_TYPES.AUTHOR;
  } else {
    const isCategory = !!router?.components?.['/blog/[slug]']?.props?.pageProps?.isCategory;
    if (isCategory) return PAGE_TYPES.CATEGORY;
    const isBlogDetails =  !!router?.components?.['/blog/[category]/[slug]'];
    if(isBlogDetails) {
      return PAGE_TYPES.DETAILS;
    }
    return PAGE_TYPES.OTHERS;
  }
};

export const getPageCategory = (router: any) => {
  const pageType = getPageType(router);
  let pageCategory;
  if (pageType === PAGE_TYPES.HOME) {
    pageCategory =  PAGE_CATEGORY.BLOG_HOME;
  } else if (pageType === PAGE_TYPES.CATEGORY) {
    pageCategory = PAGE_CATEGORY.BLOG_CATEGORY;
  } else if (pageType === PAGE_TYPES.AUTHOR) {
    pageCategory = PAGE_CATEGORY.BLOG_AUTHOR;
  } else if (pageType === PAGE_TYPES.DETAILS) {
    pageCategory = PAGE_CATEGORY.BLOG_DETAILS;
  } else {
    pageCategory = PAGE_CATEGORY.BLOG_OTHERS;
  }
  return pageCategory;
}