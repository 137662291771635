import React, { useEffect } from "react";
import { Fragment, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import Button from "../Button";
import { checkLead, formSubmitHandler } from "../Forms/formSubmitHandler";
import { notify } from "../../lib/notify";
import { ga4Events } from "../../lib/events/ga4/constant";
import { FormsContext } from "../../pages/_app";
//import { moengageEventTrack } from "../../lib/moengage";
type Props = {
  open: boolean;
  setOpen: any;
  courseID?: number;
};  
const BlogSubscribeModal: React.FC<Props> = ({ open, setOpen, courseID}) => {
  
  const {ga4EventKey, setGa4EventKey} = useContext(FormsContext);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [pageURL, setPageURL] = useState("");
  useEffect(() => {
    setPageURL(window.location.href);
  }, []);
  const onSubmit = async (data: any) => {
    // Certifying_authority check
    if (data.certifying_authority.length) {
      return;
    }
    const leadType = "blogSubscription";
    const extraData = {
      static_lead_type: "BLOG_SUBSCRIPTION",
      page: `${pageURL}`,
      ga4EventKey : ga4EventKey
    };
    
    // console.log('extradata',{extraData});
    const count = await checkLead(extraData?.static_lead_type, data?.email);
    if (count) {
      reset();
      notify("error", "Already Subscribed to Knowledgehut");
      setOpen(false);
      return;
    }
    const leadData = { ...data, ...extraData };
    if(courseID) {
      leadData["default_course_id"] = courseID;
    }

    const response = await formSubmitHandler(leadData, leadType, 0);

    

    // moengageEventTrack(
    //   "Submit",
    //   { Label: "Blog Subscription Form Submit", Name: "Blog Subscription" },
    //   leadData
    // );

    if (response.result.status) {


      reset();
      setOpen(false);
      notify(
        "success",
        "Thank You! We have sent an e-mail with a link to confirm your subscription."
      );
      return;
    }
    notify("error", response.result.message);
  };
  return (
    <>
      {open && (
        <Transition.Root show={true} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setOpen}
          >
            <div className="flex items-end justify-center xl:min-h-screen w-full pt-3  xl:pt-7 pb-20 text-center sm:block ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50  transition-opacity" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block  mx-3 xl:w-5/12 align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle w-full">
                  <div className=" sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <i className="blog-icons icon-close inline-block"></i>
                    </button>
                  </div>
                  <div>
                    <Dialog.Title
                      as="h3"
                      className="text-base font-bold leading-4 text-black mt-8 mb-4"
                    >
                      Subscribe To Our Newsletter.
                    </Dialog.Title>
                    <div className="mt-2">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {/* Certifying_authority */}
                        <input
                          className="hidden"
                          type="text"
                          {...register("certifying_authority")}
                        />
                        <div className="relative z-0 w-full group">
                          <input
                            {...register("email", {
                              required: true,
                              pattern: /\S+@\S+\.\S+/,
                            })}
                            placeholder="email"
                            className={`w-full border border-gray-500 px-3 py-2 text-black bg-transparent rounded  peer placeholder-transparent focus:outline-none focus:border-red-600 h-10`}
                          />
                          <p
                            className={`${
                              errors?.email?.type ? "visible" : "invisible"
                            } text-red-600 text-xs m-1 h-1`}
                          >
                            {errors?.email?.type === "required"
                              ? "This field is required"
                              : errors?.email?.type === "pattern"
                              ? "Please enter a valid email address."
                              : "Something went wrong"}
                          </p>
                          <label
                            className="text-black text-xs absolute left-2 -top-2   transition-all 
                       peer-placeholder-shown:text-xs peer-placeholder-shown:text-gray-400 
                      peer-placeholder-shown:top-3 peer-focus:-top-2 peer-focus:text-red-700
                       peer-focus:text-xs pointer-events-none peer-focus:px-1 bg-white  px-1"
                          >
                            Email
                          </label>
                        </div>
                        <div className="mt-3 flex justify-end "  
                          onClick={() => {                    
                            setGa4EventKey('submit-subscribe-top-nav');
                          }}
                          >
                          <Button                           
                            text="Subscribe"
                            variant="primary"
                            buttonSize="screen"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};
export default BlogSubscribeModal;
