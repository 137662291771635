import { getUtmParams } from "../../apis/utm";
import { getUserLocationData } from "../../common/userLocation";
import {
  isMobile,
  osName,
  browserVersion,
  browserName,
} from "react-device-detect";
declare const window: any;

interface ITrackerProps {
  event: string;
  data?: any;
  key?: string;
}

const generateDefaultData = async () => {
  let pageType = localStorage.getItem("pageCategory");

  let location = await getUserLocationData();
  const utmParams = getUtmParams();

  return {
    business_entity: "knowledgehut",
    country: location?.country?.name || "",
    city: location?.city?.name || "",
    state: location?.state?.name || "",
    source_identifier: isMobile ? "mobile_web" : "desktop_web",
    device_type: isMobile ? "mobile_web" : "desktop_web",
    platform_os: osName,
    platform_version: browserVersion,
    platform_agent: browserName,
    page_url: window?.location?.href,
    page_title: document?.title || "Other",
    screen_resolution: `${window?.screen?.width}*${window?.screen?.height}`,
    referrer: document?.referrer || "",
    path: window?.location?.pathname,
    page_category: pageType,
    latest_utm_campaign: utmParams?.UCAMPAIGN || "",
    latest_utm_medium: utmParams?.UMEDIUM || "",
    latest_utm_source: utmParams?.USOURCE || "",
    latest_utm_term: utmParams?.UTERM || "",
    latest_utm_content: utmParams?.UCONTENT || "",
    session_id: "NA",
    lead_identifier: "NA",
    offering_key_identifier: "NA",
    logged_in_flag: false,
    email_id: "NA",
    paid_user: "NA",
    experiment_name: "",
  };
};

export const tracker = async (props: ITrackerProps) => {
  try {
    const { event, data, key } = props;
    if (typeof window !== "undefined") {
      if (window?.rudderanalytics) {
        const defaultData = await generateDefaultData();
        const eventFinalData = { ...defaultData, ...data };
        window?.rudderanalytics.track(event, eventFinalData, {}, () => {});
      } else {
        console.error("RudderStack Not Lodded");
      }
    }
    window?.rudderanalytics?.identify;
  } catch (error) {
    console.error(`Error tracking ${props?.event} event:`, error);
  }
};

/* export const identify = (props: any): void => {
  const { userId, traits } = props;
  if (typeof window !== "undefined") {
    window?.rudderanalytics.identify(userId, {
      ...traits,
    });
  }
}; */
