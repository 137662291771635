// Format types supported
type formatProp =
  | "YYYY-MM-DD" // 2022-02-23
  | "Do M Y h:mm a" // 23rd 2 2022 9:22 pm
  | "DD MMM YYYY" // 23 Feb 2022
  | "Do MMM, YYYY"; // 23rd Feb, 2022
const FormatDate = (plainDate: string, formatType: formatProp) => {
  // Extracting Date & Time from plainDate
  const newDate: any = new Date(plainDate?.split(" ")[0]);
  const newTime: any = plainDate?.split(" ")[1].split(":");
  // Extracting date, month(Number, Text[long & short]) & year from newDate
  const date: number = newDate.toLocaleDateString("en-GB", {
    day: "2-digit",
  });
  let ordinalText: string = "";
  const monthNo: number = newDate.toLocaleDateString("en-GB", {
    month: "2-digit",
  });
  const monthTextLong: string = newDate.toLocaleDateString("en-US", {
    month: "long",
  });
  const monthTextShort: string = newDate.toLocaleDateString("en-US", {
    month: "short",
  });
  const year: number = newDate.getFullYear();
  // Extracting hour, minutes, seconds from newTime
  let hour: number = newTime[0];
  const minutes: number = newTime[1];
  const seconds: number = newTime[2];
  let period: "am" | "pm" = "am";
  // Output variable
  let formatedDate: string = "";
  // Ordinal text for date
  switch (date % 100) {
    case 11:
    case 12:
    case 13:
      ordinalText = "th";
      break;
  }

  if (ordinalText == "") {
    switch (date % 10) {
      case 1:
        ordinalText = "st";
        break;
      case 2:
        ordinalText = "nd";
        break;
      case 3:
        ordinalText = "rd";
        break;
      default:
        ordinalText = "th";
        break;
    }
  }

  // Formatting hour (24 hour to 12 hour)
  if (hour > 12) {
    hour = hour - 12;
    period = "pm";
  }
  // Changing 0 hour to 12 (0 = 12 am)
  else if (hour === 0) {
    hour = 12;
    period = "am";
  }
  // Formats done       | Example
  // --------------------------------
  // YYYY-MM-DD         | 2022-02-23
  // Do M Y h:mm a      | 23rd 2 2022 9:22 pm
  // DD MMM YYYY        | 23 Feb 2022
  // Do MMM, YYYY       | 23rd Feb, 2022
  // Formatting Date
  switch (formatType) {
    case "YYYY-MM-DD":
      formatedDate = year + "-" + monthNo + "-" + date;
      break;
    case "Do M Y h:mm a":
      formatedDate =
        // Date
        date +
        ordinalText +
        " " +
        monthNo +
        " " +
        year +
        " " +
        // Time
        hour +
        ":" +
        minutes +
        " " +
        period;
      break;
    case "DD MMM YYYY":
      formatedDate = date + " " + monthTextShort + " " + year;
      break;
    case "Do MMM, YYYY":
      formatedDate = date + ordinalText + " " + monthTextShort + ", " + year;
      break;
    default:
      formatedDate = date + ordinalText + " " + monthTextShort + ", " + year;
      break;
  }
  return formatedDate;
};
export default FormatDate;
