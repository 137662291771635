import React from "react";
import { ButtonProps } from "./interface";
const Button: React.FC<ButtonProps> = ({
  text,
  variant,
  buttonSize,
  borderRadius,
  startIcon,
  endIcon,
  onChildrenClick,
  buttonHeight,
  style
}) => {
  let className;
  let size;
  let rounded;
  switch (borderRadius) {
    case "true":
      rounded = "rounded";
      break;
    case "false":
      rounded = "rounded-none";
      break;
    default:
      "";
  }
  switch (buttonSize) {
    case "xs":
      size = "w-28";
      break;
    case "sm":
      size = "w-36";
      break;
    case "md":
      size = "w-44";
      break;
    case "lg":
      size = "w-60";
      break;
    case "screen":
      size = "w-full";
      break;
    default:
      "";
  }
  switch (variant) {
    case "outlined":
      className = `inline-flex justify-center gap-4 w-36 sm:w-48 h-10 text-sm font-semibold rounded px-1.5 py-2 sm:py-2 px-4 border border-black bg-gray-50`;
      break;
    case "primary":
      className = `inline-flex items-center justify-center sm:justify-evenly h-10 text-sm  text-white font-semibold bg-gradient-to-r from-red-500 to-pink-500 rounded xs:py-2 xs:px-4`;
      break;
    case "contained":
      className = `inline-flex items-center justify-center h-10 text-base sm:text-sm font-medium text-white bg-blue-3 px-5 py-2 rounded`;
      break;
    case "contact":
      className = `inline-flex items-center relative justify-center sm:justify-around w-full h-10 sm:w-56 sm:text-sm text-white font-semibold rounded bg-gradient-to-r from-red-500 to-pink-500`;
      break;
    case "outlinedWhite":
      className = `inline-flex items-center justify-between h-10 text-sm font-semibold text-white rounded px-2 py-2 bg-black`;
      break;
    case "disabled":
      className = `inline-flex justify-center gap-4 w-36 sm:w-48 h-10 text-sm font-semibold rounded px-1.5 py-2 sm:py-2 border border-black cursor-not-allowed bg-black text-white`;
      break;
    case "hoverOutlined":
      className = `inline-flex font-semibold h-10 rounded py-2 px-5 items-center text-sm border border-gray-400 text-black hover:text-white hover:bg-black hover:border-black`;
      break;
    case "discussionFormDisabled":
      className = `inline-flex justify-center gap-4 h-10 text-sm pointer-events-none font-semibold rounded px-1.5 py-2 sm:py-2 border border-black cursor-not-allowed bg-black text-white`;
      break;
    default:
      "";
  }
  return (
    <div className="group">
      <button
      style={style}
        className={`${className} ${size} ${rounded} ${buttonHeight === "full" && "h-full"}`}
        onClick={onChildrenClick}
      >
        {startIcon && <i className={`${startIcon} blog-icons`}></i>}
        <p className={variant == "primary" ? "ml-5 xs:ml-0" : ""  }>{text}</p>
       { endIcon && <i className={`${endIcon} blog-icons`}></i>}
      </button>
    </div>
  );
};
export default Button;
