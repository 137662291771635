import { PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";
import Accordion from "../Accordion";
import { MenuItemsData } from "../TopHeaderMenuItems/Interface";
import Link from "next/link";
import { SyntheticEvent, useEffect, useState } from "react";
import BlogSubscribeModal from "../BlogSubscribeModal";
import React from "react";
import { useRouter } from "next/router";
import menuDetails from "../MegaMenu/Data";
import { menuItemDetails } from "../MenuItems/Data";
import {
  BootcampsData,
  EnterpriseData,
  ResourcesData,
} from "../TopHeaderMenuItems/Data";
import HeaderTopSideBarLogo from "./HeaderTopSideBarLogo";
import HeaderSidebarContactUs from "./HeaderSidebarContactUs";
import { trackGa4Events } from "../../lib/events/ga4/tracker";

const HeaderTopSideBar: React.FC<any> = ({
  sideBar,
  side,
  closeAllSideBars,
}) => {
  const router = useRouter();

  const [currenetPath, setCurrentPath] = useState<string>(router.asPath);

  // Accordion style
  const AccordionBodyStyle = `flex items-center border-b border-gray-200 bg-gray-100 text-gray-10 text-13 font-semibold p-4`;

  // Accordion body
  const [showBody, setShowBody] = useState<string>("");

  // Accordion Course body
  const [showCourses, setShowCourses] = useState<string>("");

  // Sidebar accordions (Courses, More, Help) open/close
  const changeBodyStatus: any = (title: string) => {
    setShowBody(showBody === title ? "" : title);
  };

  // Courses accordions open/close
  const changeCourseStatus: any = (title: string) => {
    setShowCourses(showCourses === title ? "" : title);
  };

  // State for Subscribe modal
  const [open, setOpen] = useState(false);

  // Closes Sidebar
  const closeSideBar = () => {
    closeAllSideBars();
  };

  // Subscribe modal
  const openSubscribeModal = () => {
    closeSideBar();
    setOpen(!open);
  };

  // Checks for click on "overlay"
  const handleClose = (e: any) => {
    if (e.target?.id === "overlay") {
      closeSideBar();
    }
  };

  // Disabling / Enabling Scroll Overflow for <body>
  useEffect(() => {
    document.body.style.overflow = sideBar ? "hidden" : "auto";
  }, [sideBar]);

  return (
    <div
      className={`fixed w-screen inset-0 bg-transparent
        ${sideBar === null ? "opacity-0" : "opacity-100"}
        ${sideBar && side === "top" ? "translate-y-0" : "-translate-y-full"}
         transform transition ease-in-out duration-500 bg-transparent z-50`}
      id="overlay"
      onClick={(e: SyntheticEvent) => handleClose(e)}
    >
      <div
        className={`fixed w-screen inset-0 ${
          sideBar && side === "top" ? "translate-y-0" : "-translate-y-full"
        } transform transition ease-in-out duration-500 bg-white z-50`}
      >
        <BlogSubscribeModal open={open} setOpen={setOpen} />
        {sideBar && side === "top" && (
          <>
            {/* Heading with Close icon */}
            <div className="sticky bg-white top-0 left-0 flex justify-between items-center h-51p w-full p-4 border-b border-gray-9">
              <div className="w-86 h-31">
                <HeaderTopSideBarLogo />
              </div>
              <div
                className="cursor-pointer hover:bg-gray-100 rounded-full px-1 mr-px"
                onClick={closeSideBar}
              >
                <i className="blog-icons icon-close inline-block"></i>
              </div>
            </div>

            {/* Accordions & Links for Megamenu & Menuitems */}
            <div className="fixed flex flex-col justify-between top-51p left-0 right-0 bottom-0">
              <div className="bg-white z-50 overflow-auto customized-scrollbar">
                {/* Accordions for MegaMenu */}
                <Accordion
                  title="Explore our courses"
                  showBody={showBody}
                  changeBodyStatus={changeBodyStatus}
                  sticky={showBody === "Courses" ? true : false}
                >
                  {/* Accordions for Courses in MegaMenu */}
                  {menuDetails?.map((categoryItem: any, index: number) => (
                    <Accordion
                      title={categoryItem.name}
                      icon={<PlusSmIcon />}
                      icon2={<MinusSmIcon />}
                      leftBorder={true}
                      key={index}
                      showBody={showCourses}
                      changeBodyStatus={changeCourseStatus}
                      ga4EventKey={`mega_menu_click_menu_items{${categoryItem.name}}`}
                    >
                      {categoryItem.courses?.map((item: any, index: number) => (
                        <div
                          className={AccordionBodyStyle}
                          key={index}
                          onClick={closeSideBar}
                        >
                          <Link href={item.courseUrl}>
                            <a
                              className="w-full ml-2 text-13 text-gray-10 font-normal"
                              dangerouslySetInnerHTML={{
                                __html: item.courseName,
                              }}
                              onClick={() => {
                                trackGa4Events(
                                  `mega_menu_click_course_menu_items{${item.courseName}}`,
                                  {
                                    parameters: { category: categoryItem.name },
                                  }
                                );
                              }}
                            ></a>
                          </Link>
                        </div>
                      ))}
                      {categoryItem.courses.length >= 10 && (
                        <div className={AccordionBodyStyle} key={index}>
                          <Link href={categoryItem.link}>
                            <a className="w-full ml-2">
                              <div
                                className="flex items-center justify-between"
                                onClick={closeSideBar}
                              >
                                <div className="text-pink-2 text-13 font-medium">
                                  View All Courses
                                </div>
                                <div>
                                  <i className="inline-block icon-right-arrow-pink blog-icons"></i>
                                </div>
                              </div>
                            </a>
                          </Link>
                        </div>
                      )}
                    </Accordion>
                  ))}
                </Accordion>

                {/* Accordion for MenuItems Boot camp */}
                <Accordion
                  title="Explore our Bootcamps"
                  showBody={showBody}
                  changeBodyStatus={changeBodyStatus}
                >
                  {BootcampsData?.map(
                    ({ title, ref }: MenuItemsData, index: number) => (
                      <div
                        className={AccordionBodyStyle}
                        key={index}
                        onClick={closeSideBar}
                      >
                        <Link href={ref}>
                          <a className="w-full text-sm">{title}</a>
                        </Link>
                      </div>
                    )
                  )}
                </Accordion>
              </div>

              {/* Subscribe & SignIn buttons */}
              <HeaderSidebarContactUs openSubscribeModal={openSubscribeModal} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderTopSideBar;
