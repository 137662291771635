import { FC } from "react";
import Button from "../Button/index";

const HeaderSidebarContactUs: FC<any> = ({ openSubscribeModal }) => {
  return (
    <div
      className={`border-t-3 border-gray-9 p-4 px-4 pb-4 w-full h-178 bg-white text-center`}
    >
      <div className="font-semibold text-xs leading-4 mb-2">
        Did not find what you here looking for?
      </div>

      <div className="font-normal text-10 leading-[14px] text-gray-15 mb-4">
        We’ll help you find it
      </div>

      <div className="flex justify-center mb-6">
        <div className="w-103 h-30 border border-gray-17 rounded flex items-center justify-center font-semibold text-sm">
          <a href="tel:+1-469-442-0620" rel="nofollow" className="inline-flex items-center">
            <i className="inline-block blog-icons blog-header-call-icon mr-1"></i>
            Call us
          </a>
        </div>
      </div>

      <Button
        text="Subscribe"
        variant="primary"
        borderRadius="true"
        buttonSize="screen"
        buttonHeight="full"
        onChildrenClick={openSubscribeModal}
        style={{
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "19px",
          color: "#FFFFFF",
          backgroundColor: "#f0f0f0",
          background:
            "linear-gradient(150.06deg, #FF702A -73.12%, #C507C9 182.65%)",
        }}
      />
    </div>
  );
};

export default HeaderSidebarContactUs;
