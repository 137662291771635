import axios from "axios";
import { getWhatsAppLinkDataByParams } from "../../lib/apis/blog";
import { getIP } from "../../lib/apis/ipApi";
import { getUtmParams, randomCodeGenerator } from "../../lib/apis/utm";
import storeDataToLocal from "./StoreDatatToLocalStorage";
import { trackGa4Events } from "../../lib/events/ga4/tracker";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
import { getUserLocationData } from "../../lib/common/userLocation";

// form submit handler
export const formSubmitHandler = async (
  data: any,
  leadType: string,
  countryID: number,
  setLeadData?: any
) => {
  //const ipAddress = await publicIp.v4();
  /* const ipData = await getIP();
  const ipAddress = ipData.ip; */
  let userLocationData;
  try {
    userLocationData = await getUserLocationData();
  } catch (error) {
    console.error("Error fetching user location data:", error);
  }

  const ip = require("ip");
  const ipAddress = ip.address();
  const utmParams = getUtmParams();
  const randomCode = randomCodeGenerator();
  const defaultData = {
    code: randomCode,
    ip_address: ipAddress,
    utm_parameters: utmParams,
    country_id: userLocationData?.country?.id,
    city_id: userLocationData?.city?.id,
  };

  const formData = { ...data, ...defaultData };
  
  const url = "/v1/leads";

  const headers = {
    "Content-type": "application/json",
  };

  const response = await axios.post(url, JSON.stringify(formData), {
    headers,
  });

  if (response.data.success == true) {
    setLeadData &&
      setLeadData({ submitResponse: response.data.result, submittedData: formData });

    const callBackURL = `{"callback":"${
      publicRuntimeConfig?.NEXT_PUBLIC_WEB_DOMAIN ||
      process.env.NEXT_PUBLIC_WEB_DOMAIN
    }/forms/send_lead_mail/${leadType}/${response.data.result.id}"}`;

    //publish snsmessage
    if (leadType && leadType != "oslFreeTrail" && !formData.isSendOTP) {
      const result = await axios.post(
        `${
          publicRuntimeConfig?.NEXT_PUBLIC_BLOG_API ||
          process.env.NEXT_PUBLIC_BLOG_API
        }/snsPublish`,
        { url: callBackURL },
        { headers }
      );
    }

    if (formData.isSendOTP) {
      let otpCallBackURL = `{"callback":"${
        publicRuntimeConfig?.NEXT_PUBLIC_WEB_DOMAIN ||
        process.env.NEXT_PUBLIC_WEB_DOMAIN
      }/forms/send_otp_mail/${
        response.data.result.id
      }/BLOG_EXIT_POPUP_OTP_VERIFY_USER"}`;

      const result = await axios.post(
        `${
          publicRuntimeConfig?.NEXT_PUBLIC_BLOG_API ||
          process.env.NEXT_PUBLIC_BLOG_API
        }/snsPublish`,
        { url: otpCallBackURL },
        { headers }
      );
    }

    storeDataToLocal(data);

    let socialData = null;

    if (formData.intrested_course_id && countryID) {
      // get socialLink data
      socialData = await getWhatsAppLinkDataByParams(
        parseInt(formData.intrested_course_id),
        countryID
      );
    }
    
    // Ga4 events tracker
    if(typeof formData.ga4EventKey != "undefined")
    {      
      trackGa4Events(formData.ga4EventKey, {...formData});
    }
      
    return {
      result: {
        id: response.data.result.id,
        message: response.data.result.message,
        status: response.data.success,
        socialData: socialData,
      },
    };
  }

  return {
    result: {
      id: "",
      message: "Lead Not Created",
      status: false,
    },
  };
};

// checkLead
export const checkLead = async (
  code: string,
  email: string,
  blog_id?: number
) => {
  const response = await axios.get(`/v1/leads`, {
    params: { code, email, blog_id },
    validateStatus: () => true,
  });

  return response.data.success ? response.data.totalCount : 0;
};

// form blogActiveSubscription
export const blogActiveSubscription = async (data: any, leadType: string) => {
  const formData = { ...data };

  const url = "/v1/activate_subscription";

  const headers = {
    "Content-type": "application/json",
  };

  const response = await axios.put(url, JSON.stringify(formData), {
    headers,
  });

  if (response.data.success == true) {
    const callBackURL = `{"callback":"${
      publicRuntimeConfig?.NEXT_PUBLIC_WEB_DOMAIN ||
      process.env.NEXT_PUBLIC_WEB_DOMAIN
    }/forms/send_lead_mail/${leadType}/${response.data.result.id}"}`;

    //publish snsmessage
    if (leadType != "oslFreeTrail") {
      const result = await axios.post(
        `${
          publicRuntimeConfig?.NEXT_PUBLIC_BLOG_API ||
          process.env.NEXT_PUBLIC_BLOG_API
        }/snsPublish`,
        { url: callBackURL },
        { headers }
      );
    }

    return {
      result: {
        id: response.data.result.id,
        message: response.data.result.message,
        status: response.data.success,
      },
    };
  }

  return {
    result: {
      id: "",
      message: "Lead Not Created",
      status: false,
    },
  };
};


// resendMail
export const resendMail = async (leadID: string) => {
  const headers = {
    'Content-type': 'application/json',
  };
  const OTPCallBackURL = `{"callback":"${
    publicRuntimeConfig?.NEXT_PUBLIC_WEB_DOMAIN ||
    process.env.NEXT_PUBLIC_WEB_DOMAIN
  }/forms/send_otp_mail/${leadID}/BLOG_EXIT_POPUP_OTP_VERIFY_USER"}`;
  const result = await axios.post(
    `${
      publicRuntimeConfig?.NEXT_PUBLIC_BLOG_API ||
      process.env.NEXT_PUBLIC_BLOG_API
    }/snsPublish`,
    { url: OTPCallBackURL },
    { headers }
  );

  return {
    result: {
      id: '',
      message: 'OTP RESEND',
      status: true,
    },
  };
};
