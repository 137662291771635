import axios from "axios";
import { getLocationByIPAPI } from "./ipApi";
import { City, Country, State, UserLocation } from "../../Interfaces/city";
import { getCountryByBName, getDefaultCityByCountryID } from "./country";
import { getStateByName } from "./state";
import { getCityBySlug } from "./city";
import { getCookieByParams } from "./utm";

// setLocation
export async function setLocation(
  type: string,
  value: string | City,
  reqUserAgent: string
): Promise<any> {
  let returnData = null;
  switch (type) {
    case "ip":
      console.log({ ip: value });
      // check value string or not
      if (typeof value != "string")
      {
        console.log('comming inside value not string');
        return setDefaultLocation();
      }

      const location = await getLocationByIPAPI(value);
      
      if (location.status != "fail") {
        // country
        const country: Country = await getCountryByBName(location.country);

        if (!country) {
          console.log('comming inside country false');
          return setDefaultLocation();
        }

        // locationData
        const locationData = {
          country: {
            id: country.id,
            code: country.code,
            name: country.name,
            slug: country.slug,
            isd: country.isd,
          },
          region: {
            id: country.region.id,
            name: country.region.name,
            slug: country.region.slug,
          },
          currency: {
            id: country.currency.id,
            name: country.currency.name,
          },
          city: {},
        };

        // state
        const state: any = await getStateByName(
          location.regionName,
          location.city,
          location.timezone
        );

        if (!state) {
          console.log('comming inside state false');
          const city: City = await getDefaultCityByCountryID(country.id);

          if (!city) {
            return setDefaultLocation();
          }

          locationData.city = {
            id: city.id,
            name: city.name,
            slug: city.slug,
            timezone_abbr: city.timezone_abbr,
            timezone_name: city?.timezone?.name,
          };
        } else {
          locationData.city = {
            id: state.cities[0].id,
            name: state.cities[0].name,
            slug: state.cities[0].slug,
            timezone_abbr: state.cities[0].timezone_abbr,
            timezone_name:
              state?.cities[0]?.timezone_name ||
              state?.cities[0]?.timezone?.timezone,
          };
        }

        // Return
        returnData = locationData;
      } else {
        console.log('comming inside location false');
        // Return
        returnData = setDefaultLocation();
      }
      break;

    case "city":
      // check value string or not
      if (typeof value == "string") return setDefaultLocation();

      const city = value;
      const locationData = {
        country: {
          id: city?.country?.id,
          code: city?.country?.code,
          name: city?.country?.name,
          slug: city?.country?.slug,
          isd: city?.country?.isd,
        },
        region: {
          id: city?.country?.region.id,
          name: city?.country?.region.name,
          slug: city?.country?.region.slug,
        },
        currency: {
          id: city?.country?.currency.id,
          name: city?.country?.currency.name,
        },
        city: {
          id: city?.id,
          name: city?.name,
          slug: city?.slug,
          timezone_abbr: city?.timezone_abbr,
          timezone_name: city?.timezone?.name,
        },
      };

      //return locationData;
      returnData = locationData;
      break;
  }

  return returnData;
}

// setDefaultLocation
export async function setDefaultLocation() {
  // getCityBySlug
  const city: City = await getCityBySlug("newyork");
  return setLocation("city", city, "");
}

// getCurrentLocation
export function getCurrentLocation() {
  const locationData = getCookieByParams({
    location: "user-location",
  });

  return JSON.parse(decodeURIComponent(locationData.location));
}

// getCountryCitiesList
export async function getCountryCitiesList() {
  const locationList = await axios.get(`kh/countries_and_cities_list`, {
    params: { status: 1 },
    validateStatus: () => true,
  });

  if (locationList.data.success == false) return null;

  const courseCountries = locationList.data.result.map((location: any) => {
    return {
      code: location.isd,
      country: location.name,
    };
  });

  return courseCountries;
}
