let menuDetails: Array<any> = [
  {
    id: "1",
    name: "Agile Methodology",
    link: "/agile-management",
    courses: [
      {
        courseName: "Certified ScrumMaster (CSM) Certification",
        courseUrl: "/agile-management/csm-certification-training",
      },
      {
        courseName: "Certified Scrum Product Owner(CSPO) Certification",
        courseUrl: "/agile-management/cspo-certification-training",
      },
      {
        courseName: "Leading SAFe 6.0 Certification",
        courseUrl: "/agile-management/leading-safe-certification-training",
      },
      {
        courseName: "Professional Scrum Master(PSM) Certification",
        courseUrl: "/agile-management/psm-certification-training",
      },
      {
        courseName: "SAFe 6.0 Scrum Master with SSM Certification",
        courseUrl:
          "/agile-management/safe-40-scrum-master-with-ssm-certification-training",
      },
      {
        courseName: "Implementing SAFe 6.0 with SPC Certification",
        courseUrl: "/agile-management/spc-certification-training",
      },
      {
        courseName: "SAFe 6.0 Release Train Engineer (RTE) Certification",
        courseUrl:
          "/agile-management/safe-release-train-engineer-certification-training",
      },
      {
        courseName: "SAFe POPM Certification",
        courseUrl:
          "/agile-management/safe-product-manager-product-owner-training",
      },
      {
        courseName: "Kanban Certification(KMP I: Kanban System Design)",
        courseUrl: "/agile-management/kanban-system-design-training",
      },
      {
        courseName: "ICP-ACC Certification",
        courseUrl:
          "/agile-management/icp-agile-certified-coach-icp-acc-training",
      },
      {
        courseName: "Professional Scrum Product Owner Level I (PSPO) Training",
        courseUrl:
          "/agile-management/professional-scrum-product-owner-pspo-training",
      },
    ],
    accredition: [
      {
        accreditionName: "Scrum Alliance",
        accreditionUrl: "/certifications/scrum-alliance",
        accreditionIcon: "acdtn-page-28",
      },
      {
        accreditionName: "IC Agile",
        accreditionUrl: "",
        accreditionIcon: "acdtn-page-14",
      },
      {
        accreditionName: "Scrum.Org",
        accreditionUrl: "/certifications/scrum-org",
        accreditionIcon: "acdtn-page-15",
      },
      {
        accreditionName: "Scaled Agile",
        accreditionUrl: "/certifications/scaled-agile",
        accreditionIcon: "acdtn-page-30",
      },
    ],
  },
  {
    id: "2",
    name: "Project Management",
    link: "/project-management-certifications",
    courses: [
      {
        courseName: "PMP Certification Training",
        courseUrl: "/project-management/pmp-certification-training",
      },
      {
        courseName: "PRINCE2 Certification",
        courseUrl:
          "/project-management/prince2-foundation-and-practitioner-certification-training",
      },
      {
        courseName: "PRINCE2 Foundation Certification",
        courseUrl:
          "/project-management/prince2-foundation-certification-training",
      },
      {
        courseName: "PRINCE2 Practitioner Certification",
        courseUrl:
          "/project-management/prince2-practitioner-certification-training",
      },
      {
        courseName: "Change Management Certification",
        courseUrl: "/project-management/change-management-training",
      },
      {
        courseName: "Project Management Techniques Training",
        courseUrl: "/project-management/project-management-techniques-training",
      },
      {
        courseName: "CAPM Certification Training",
        courseUrl: "/project-management/capm-certification-training",
      },
      {
        courseName: "PgMP Certification Training",
        courseUrl: "/project-management/pgmp-certification-training",
      },
      {
        courseName: "PfMP Certification Training",
        courseUrl: "/project-management/pfmp-certification-training",
      },
      {
        courseName: "Oracle Primavera P6 Certification Training",
        courseUrl: "/project-management/oracle-primavera-p6-v8-training",
      },
      {
        courseName: "Microsoft Project Training",
        courseUrl: "/project-management/microsoft-project-2013-training",
      },
    ],
    accredition: [
      {
        accreditionName: "PMI",
        accreditionUrl: "/certifications/pmi",
        accreditionIcon: "acdtn-page-1",
      },
      {
        accreditionName: "AXELOS",
        accreditionUrl: "",
        accreditionIcon: "acdtn-page-11",
      },
    ],
  },
  {
    id: "3",
    name: "Data Science",
    link: "/data-science-courses",
    courses: [
      {
        courseName: "Data Science Career Track Bootcamp",
        courseUrl: "/data-science/data-science-bootcamp-training",
      },
      {
        courseName: "Data Engineer Bootcamp",
        courseUrl: "/data-science/data-engineer-bootcamp-course",
      },
      {
        courseName: "Data Analyst Bootcamp",
        courseUrl: "/data-science/data-analyst-bootcamp-course",
      },
      {
        courseName: "AI Engineer Bootcamp",
        courseUrl: "/data-science/ai-engineer-bootcamp-course",
      },
      {
        courseName: "Data Science with Python Certification",
        courseUrl:
          "/data-science/data-science-with-python-certification-training",
      },
      {
        courseName: "Python for Data Science",
        courseUrl:
          "/data-science/python-for-data-science-certification-training",
      },
      {
        courseName: "Machine Learning with Python",
        courseUrl:
          "/data-science/machine-learning-with-python-certification-training",
      },
      {
        courseName: "Data Science with R",
        courseUrl: "/data-science/data-science-with-r-certification-training",
      },
      {
        courseName: "Machine Learning with R",
        courseUrl:
          "/data-science/machine-learning-with-r-certification-training",
      },
      {
        courseName: "Deep Learning Certification Training",
        courseUrl: "/data-science/deep-learning-certification-training",
      },
      {
        courseName: "Natural Language Processing (NLP)",
        courseUrl:
          "/data-science/learn-natural-language-processing-with-python-nlp-course",
      }
    ],
    bootcamps: [
      {
        bootcampsName: "Data Science BootCamp",
        bootcampsDescription:
          "Enhance your career prospects with our Data Science Training",
        bootcampsUrl: "/data-science/data-science-bootcamp-training",
      },
    ],
  },
  {
    id: "4",
    name: "DevOps",
    link: "/devops-courses",
    courses: [
      {
        courseName: "DevOps Foundation Certification",
        courseUrl: "/devops/devops-foundation-certification-training",
      },
      {
        courseName: "Docker with Kubernetes Training",
        courseUrl: "/devops/docker-with-kubernetes-training",
      },
      {
        courseName: "Kubernetes Training",
        courseUrl: "/devops/kubernetes-training",
      },
      {
        courseName: "Docker Training",
        courseUrl: "/devops/docker-training",
      },
      {
        courseName: "DevOps Training",
        courseUrl: "/devops/devops-training",
      },
      {
        courseName: "DevOps Leader Training",
        courseUrl: "/devops/devOps-leader-training",
      },
      {
        courseName: "Jenkins Training",
        courseUrl: "/devops/jenkins-training",
      },
      {
        courseName: "OpenStack Training",
        courseUrl: "/devops/openstack-training",
      },
      {
        courseName: "Ansible Certification",
        courseUrl: "/devops/ansible-training",
      },
      {
        courseName: "Chef Training",
        courseUrl: "/devops/chef-training",
      },
      {
        courseName: "Puppet Training",
        courseUrl: "/devops/puppet-training",
      },
    ],
    accredition: [
      {
        accreditionName: "DevOps Institute",
        accreditionUrl: "",
        accreditionIcon: "acdtn-page-8",
      },
    ],
  },
  {
    id: "5",
    name: "Cloud Computing",
    link: "/cloud-computing-courses",
    courses: [
      {
        courseName: "Aws Certified Solutions Architect - Associate",
        courseUrl:
          "/cloud-computing/aws-solution-architect-certification-training",
      },
      {
        courseName: "Architecting On Aws",
        courseUrl:
          "/cloud-computing/architecting-on-aws-certification-training",
      },
      {
        courseName: "Aws Cloud Practitioner Certification",
        courseUrl:
          "/cloud-computing/aws-cloud-practitioner-essentials-certification",
      },
      {
        courseName: "Developing On Aws",
        courseUrl:
          "/cloud-computing/developing-on-aws",
      },
      {
        courseName: "Aws Devops Certification",
        courseUrl:
          "/cloud-computing/devops-engineering-on-aws-certification-training",
      },
      {
        courseName: "Azure Fundamentals Certification",
        courseUrl:
          "/cloud-computing/microsoft-azure-fundamentals-training",
      },
      {
        courseName: "Azure Administrator Certification",
        courseUrl:
          "/cloud-computing/microsoft-azure-administrator-training",
      },
      {
        courseName: "Azure Data Engineer Certification",
        courseUrl:
          "/cloud-computing/microsoft-azure-data-engineering-certification-training",
      },
      {
        courseName: "Azure Solution Architect Certification",
        courseUrl:
          "/cloud-computing/designing-microsoft-azure-infrastructure-solutions-certification-training",
      },
      {
        courseName: "Azure Devops Certification",
        courseUrl:
          "/devops/designing-and-implementing-microsoft-devOps-solutions-training",
      },
      {
        courseName: "Sysops Certification",
        courseUrl:
          "/cloud-computing/aws-sysops-administrator-associate-certification-training",
      },
      {
        courseName: "Azure Security Engineer Certification",
        courseUrl:
          "/cloud-computing/microsoft-azure-security-engineer-certification-training",
      },
    ],
  },
  {
    id: "6",
    name: "IT Service Management",
    link: "/itsm-certifications",
    courses: [
      {
        courseName: "TOGAF Certification",
        courseUrl: "/it-service-management/togaf-prep-course",
      },
      {
        courseName: "ITIL 4 Foundation Certification",
        courseUrl:
          "/it-service-management/itil-foundation-certification-training",
      },
      {
        courseName: "ITIL Practitioner Certification",
        courseUrl: "/it-service-management/itil-practitioner",
      },
      {
        courseName: "ISO 14001 Foundation Certification",
        courseUrl: "/it-service-management/iso-14001-foundation-certification-training",
      },
      {
        courseName: "ISO 20000 Certification",
        courseUrl: "/it-service-management/iso-20000-certification-training",
      },
      {
        courseName: "ISO 27000 Foundation Certification",
        courseUrl: "/it-service-management/iso-27000-foundation-certification-training",
      },
      {
        courseName: "ITIL 4 Specialist: Create, Deliver and Support Training",
        courseUrl: "/it-service-management/itil-specialist-cds-certification-training",
      },
      {
        courseName: "ITIL 4 Specialist: Drive Stakeholder Value Training",
        courseUrl: "/it-service-management/itil-specialist-dsv-certification-training",
      },
      {
        courseName: "ITIL 4 Strategist Direct, Plan and Improve Training",
        courseUrl: "/it-service-management/itil-strategist-direct-plan-improve-training",
      },
    ],
  },
  {
    id: "7",
    name: "Web Development",
    link: "/web-development-courses",
    courses: [
      {
        courseName: "Full Stack Development Career Track Bootcamp",
        courseUrl: "/web-development/fullstack-development-bootcamp-training",
      },
      {
        courseName: "UI/UX Design Bootcamp",
        courseUrl: "/web-development/ui-ux-design-bootcamp-course",
      },
      {
        courseName: "Full-Stack [Java Stack] Bootcamp",
        courseUrl: "/web-development/fsd-java-stack-bootcamp-training",
      },
      {
        courseName: "Front-end Development Bootcamp",
        courseUrl: "/web-development/front-end-development-bootcamp-training",
      },
      {
        courseName: "Backend Development Bootcamp",
        courseUrl: "/web-development/back-end-development-bootcamp-training",
      },
      {
        courseName: "React Training",
        courseUrl: "/web-development/react-js",
      },
      {
        courseName: "Node JS Training ",
        courseUrl: "/web-development/node-js-training",
      },
      {
        courseName: "Angular Training (Version 12)",
        courseUrl: "/web-development/angular-js-training",
      },
      {
        courseName: "Javascript Training",
        courseUrl: "/web-development/javascript",
      },
      {
        courseName: "PHP and MySQL Training",
        courseUrl: "/web-development/php-and-mysql-training",
      }
    ],
    bootcamps: [
      {
        bootcampsName: "Full-Stack Developer Bootcamp",
        bootcampsDescription:
          "Work on real-world projects, build practical developer skills",
        bootcampsUrl:
          "/web-development/fullstack-development-bootcamp-training",
      },
      {
        bootcampsName: "Front-End Development Bootcamp",
        bootcampsDescription:
          "Hands-on, work experience-based learning",
        bootcampsUrl:
          "/web-development/front-end-development-bootcamp-training",
      },
      {
        bootcampsName: "Full-Stack [Java Stack] Bootcamp",
        bootcampsDescription:
          "Start building in-demand tech skills",
        bootcampsUrl:
          "/web-development/fsd-java-stack-bootcamp-training",
      },
    ],
  },
  {
    id: "8",
    name: "Programming",
    link: "/programming-certification",
    courses: [
      {
        courseName: "Faang/Maang Interview Preparation",
        courseUrl: "/interview-preparation-courses/software-engineer",
      },
      {
        courseName: "Python Certification Training",
        courseUrl: "/programming/python-programming-certification-training",
      },
      {
        courseName: "Advanced Python Course",
        courseUrl: "/programming/python-deep-dive-training",
      },
      {
        courseName: "R Programming Language Certification",
        courseUrl: "/programming/r-programming-language-certification-training",
      },
      {
        courseName: "Advanced R Course",
        courseUrl: "/programming/r-deep-dive-training",
      },
      {
        courseName: "Java Training",
        courseUrl: "/programming/java-training",
      },
      {
        courseName: "Java Deep Dive",
        courseUrl: "/programming/java-deep-dive-training",
      },
      {
        courseName: "Scala Training",
        courseUrl: "/programming/scala-training",
      },
      {
        courseName: "Advanced Scala",
        courseUrl: "/programming/advanced-scala",
      },
      {
        courseName: "C# training",
        courseUrl: "/programming/c-sharp",
      },
      {
        courseName: "Microsoft .Net Framework Training",
        courseUrl: "/programming/microsoft-net-framework-training",
      }
    ],
  },
  {
    id: "9",
    name: "Bi And Visualization",
    link: "/business-intelligence-and-visualization",
    courses: [
      {
        courseName: "Tableau Certification",
        courseUrl: "/business-intelligence-and-visualization/tableau-training",
      },
      {
        courseName: "Data Visualisation with Tableau Certification",
        courseUrl:
          "/business-intelligence-and-visualization/data-visualisation-with-tableau",
      },
      {
        courseName: "Microsoft Power BI Certification",
        courseUrl:
          "/business-intelligence-and-visualization/microsoft-power-bi-training",
      },
      {
        courseName: "TIBCO Spotfire Training",
        courseUrl:
          "/business-intelligence-and-visualization/tibco-spotfire-training",
      },
      {
        courseName: "Data Visualization with QlikView Certification",
        courseUrl:
          "/business-intelligence-and-visualization/data-visualization-with-qlikview",
      },
      {
        courseName: "Sisense BI Certification",
        courseUrl: "/business-intelligence-and-visualization/sisense-bi",
      },
    ],
  },
  {
    id: "10",
    name: "Blockchain",
    link: "/blockchain-courses",
    courses: [
      {
        courseName: "Blockchain Professional Certification",
        courseUrl: "/blockchain/blockchain-professional-certification-course",
      },
      {
        courseName: "Blockchain Solutions Architect Certification",
        courseUrl: "/blockchain/blockchain-solutions-architect-certification-training",
      },
      {
        courseName: "Blockchain Security Engineer Certification",
        courseUrl: "/blockchain/blockchain-security-certification-training",
      },
      {
        courseName: "Blockchain Quality Engineer Certification",
        courseUrl: "/blockchain/blockchain-quality-engineer-certification-training",
      },
      {
        courseName: "Blockchain 101 Certification",
        courseUrl: "/blockchain/blockchain-101-certification-training",
      },
    ]
  },
  {
    id: "11",
    name: "Big data",
    link: "/big-data-courses",
    courses: [
      {
        courseName: "Hadoop Administration Course",
        courseUrl: "/big-data/hadoop-administration-training",
      },
      {
        courseName: "Big Data and Hadoop Course",
        courseUrl: "/big-data/big-data-and-hadoop-training",
      },
      {
        courseName: "Big Data Analytics Course",
        courseUrl: "/big-data/big-data-analytics-training",
      },
      {
        courseName: "Apache Spark and Scala Training",
        courseUrl: "/big-data/apache-spark-and-scala-training",
      },
      {
        courseName: "Apache Storm Training",
        courseUrl: "/big-data/apache-storm-training",
      },
      {
        courseName: "Apache Kafka Training",
        courseUrl: "/big-data/apache-kafka-training",
      },
      {
        courseName: "Machine Learning with Apache Mahout Training",
        courseUrl: "/big-data/machine-learning-with-apache-mahout",
      },
      {
        courseName: "Comprehensive Pig Training",
        courseUrl: "/big-data/comprehensive-pig",
      },
      {
        courseName: "Comprehensive Hive Training",
        courseUrl: "/big-data/comprehensive-hive",
      },
    ],
  },
  {
    id: "12",
    name: "Mobile App Development",
    link: "/mobile-app-development-courses",
    courses: [
      {
        courseName: "Android Development Course",
        courseUrl: "/mobile-app-development/android-development-training",
      },
      {
        courseName: "IOS Development Course",
        courseUrl: "/mobile-app-development/ios-development-training",
      },
      {
        courseName: "React Native Course",
        courseUrl: "/mobile-app-development/react-native",
      },
      {
        courseName: "Ionic Training",
        courseUrl: "/mobile-app-development/ionic-training",
      },
      {
        courseName: "Xamarin Studio Training",
        courseUrl: "/mobile-app-development/xamarin-studio",
      },
      {
        courseName: "Xamarin Certification",
        courseUrl: "/mobile-app-development/xamarin-training",
      },
      {
        courseName: "OpenGL Training",
        courseUrl: "/mobile-app-development/opengl",
      },
      {
        courseName: "NativeScript for Mobile App Development",
        courseUrl:
          "/mobile-app-development/nativescript-for-mobile-app-development",
      },
    ],
  },
  {
    id: "13",
    name: "Software Testing",
    link: "/software-testing-courses",
    courses: [
      {
        courseName: "Selenium Certification Training",
        courseUrl: "/software-testing/selenium-essentials",
      },
      {
        courseName: "ISTQB Foundation Certification",
        courseUrl: "/software-testing/istqb-foundation-training",
      },
      {
        courseName: "ISTQB Advanced Level Security Tester Training",
        courseUrl:
          "/software-testing/istqb-certified-advanced-level-security-tester-training",
      },
      {
        courseName: "ISTQB Advanced Level Test Manager Certification",
        courseUrl:
          "/software-testing/istqb-certified-advanced-level-test-manager-training",
      },
      {
        courseName: "ISTQB Advanced Level Test Analyst Certification",
        courseUrl:
          "/software-testing/istqb-certified-advanced-level-test-analyst-training",
      },
      {
        courseName: "ISTQB Advanced Level Technical Test Analyst Certification",
        courseUrl:
          "/software-testing/istqb-advanced-level-technical-test-analyst-training",
      },
      {
        courseName: "Silk Test Workbench Training",
        courseUrl: "/software-testing/silk-test-workbench",
      },
      {
        courseName: "Automation Testing using TestComplete Training",
        courseUrl: "/software-testing/automation-testing-using-testcomplete",
      },
      {
        courseName: "Cucumber Training",
        courseUrl: "/software-testing/cucumber-training",
      },
      {
        courseName: "Functional Testing Using Ranorex Training",
        courseUrl:
          "/software-testing/functional-testing-using-ranorex-training",
      },
      {
        courseName: "Teradata Certification Training",
        courseUrl: "/software-testing/teradata",
      },
    ],
  },
  {
    id: "14",
    name: "Business Management",
    link: "/business-management-courses",
    courses: [
      {
        courseName: "CBAP Certification",
        courseUrl: "/business-management/cbap-certification-training",
      },
      {
        courseName: "ECBA Certification",
        courseUrl:
          "/business-management/entry-certificate-in-business-analysisecba-training",
      },
      {
        courseName: "CCBA Certification",
        courseUrl:
          "/business-management/certification-of-competency-in-business-analysis-certification-training",
      },
      {
        courseName: "Business Case Writing course",
        courseUrl: "/business-management/business-case-writing-training",
      },
      {
        courseName: "PMI-PBA Certification",
        courseUrl: "/business-management/pmi-pba-certification-training",
      },
      {
        courseName: "Agile Business Analysis Certification",
        courseUrl: "/business-management/agile-business-analysis-training",
      },
    ],
  },
  {
    id: "15",
    name: "Quality Management",
    link: "/quality-management-courses",
    courses: [
      {
        courseName: "Six Sigma Green Belt Certification",
        courseUrl:
          "/quality-management/lean-six-sigma-green-belt-certification-training",
      },
      {
        courseName: "Six Sigma Black Belt Certification",
        courseUrl:
          "/quality-management/lean-six-sigma-black-belt-certification-training",
      },
      {
        courseName: "Six Sigma Yellow Belt Certification",
        courseUrl:
          "/quality-management/lean-six-sigma-yellow-belt-certification-training",
      },
      {
        courseName: "CMMI<sup>®</sup>V1.3 Training",
        courseUrl: "/quality-management/cmmi-training",
      },
    ],
  },
  {
    id: "16",
    name: "IT Security",
    link: "/cyber-security-courses",
    courses: [
      {
        courseName: "Ethical Hacking Certification (CEH v11)",
        courseUrl: "/it-security/certified-ethical-hacker-v10-training",
      },
      {
        courseName: "CISA Certification",
        courseUrl: "/it-security/cisa-certification-training",
      },
      {
        courseName: "CISM Certification",
        courseUrl: "/it-security/cism-training",
      },
      {
        courseName: "CISSP Certification",
        courseUrl: "/it-security/cissp-training",
      },
      {
        courseName: "CCSP Certification",
        courseUrl: "/it-security/ccsp-certification-training",
      },
      {
        courseName: "CIPP/E-Certification Training",
        courseUrl: "/it-security/CIPP-E-certification-training",
      },
      {
        courseName: "COBIT5 Foundation Certification",
        courseUrl: "/it-security/cobit-5-foundation-training",
      },
      {
        courseName: "PCI DSS Certification",
        courseUrl: "/it-security/pci-data-security-standard-training",
      },
      {
        courseName: "Introduction to Forensic",
        courseUrl: "/it-security/introduction-to-forensic-training",
      },
    ],
  },
  {
    id: "17",
    name: "Digital Marketing",
    link: "/digital-marketing-courses",
    courses: [
      {
        courseName: "Digital Marketing Course",
        courseUrl: "/digital-marketing-courses/digital-marketing-training",
      },
      {
        courseName: "SEO Course",
        courseUrl: "/digital-marketing-courses/search-engine-optimization",
      },
      {
        courseName: "PPC Course",
        courseUrl: "/digital-marketing-courses/pay-per-click-advertising",
      },
      {
        courseName: "Web Analytics Course",
        courseUrl: "/digital-marketing-courses/web-analytics",
      },
      {
        courseName: "Social Media Marketing Course",
        courseUrl: "/digital-marketing-courses/social-media",
      },
      {
        courseName: "Content Marketing Course",
        courseUrl: "/digital-marketing-courses/content-marketing",
      },
      {
        courseName: "E-mail Marketing Course",
        courseUrl: "/digital-marketing-courses/e-mail-marketing",
      },
      {
        courseName: "Display Advertising Course",
        courseUrl: "/digital-marketing-courses/display-advertising",
      },
      {
        courseName: "Conversion Optimization Course",
        courseUrl: "/digital-marketing-courses/conversion-optimization",
      },
      {
        courseName: "Mobile Marketing Course",
        courseUrl: "/digital-marketing-courses/mobile-marketing",
      },
    ],
  },
  {
    id: "18",
    name: "Risk Management",
    link: "/risk-management",
    courses: [
      {
        courseName:
          "Introduction to the European Union General Data Protection Regulation",
        courseUrl:
          "/risk-management/introduction-to-the-european-union-general-data-protection-regulation-training",
      },
      {
        courseName: "FRM<sup>®</sup> Level 1 Certification",
        courseUrl: "/risk-management/frm-level-1-certification-training",
      },
      {
        courseName: "FRM<sup>®</sup>Level 2 Certification",
        courseUrl: "/risk-management/frm-level-2-certification-training",
      },
      {
        courseName: "RISK MANAGEMENT AND INTERNAL CONTROLS",
        courseUrl:
          "/risk-management/risk-management-and-internal-controls-training",
      },
      {
        courseName: "Data Protection-Associate",
        courseUrl: "/risk-management/dataprotection-associate",
      },
    ],
  },
  {
    id: "19",
    name: "Finance",
    link: "/finance",
    courses: [
      {
        courseName: "Credit Risk Management",
        courseUrl: "/finance/credit-risk-management-training",
      },
      {
        courseName: "Budget Analysis and Forecasting",
        courseUrl: "/finance/budget-analysis-and-forecasting-training",
      },
      {
        courseName: "IFRS for SMEs",
        courseUrl: "/finance/ifrs-for-smes-training",
      },
      {
        courseName: "Diploma In International Financial Reporting",
        courseUrl:
          "/finance/diploma-in-international-financial-reporting-training",
      },
      {
        courseName: "Certificate in International Financial Reporting",
        courseUrl:
          "/finance/certificate-in-international-financial-reporting-training",
      },
      {
        courseName: "Corporate Governance",
        courseUrl: "/finance/corporate-governance",
      },
      {
        courseName: "Finance for Non-Finance Managers",
        courseUrl: "/finance/finance-for-non-finance-managers-training",
      },
      {
        courseName: "Financial Modeling with Excel",
        courseUrl: "/finance/financial-modeling-with-excel-training",
      },
      {
        courseName: "Auditing and Assurance",
        courseUrl: "/finance/auditing-and-assurance-training",
      },
    ],
  },
  {
    id: "20",
    name: "Database",
    link: "/database-courses",
    courses: [
      {
        courseName: "MySQL Course",
        courseUrl: "/database-courses/mysql",
      },
      {
        courseName: "Redis Certification",
        courseUrl: "/database-courses/redis",
      },
      {
        courseName: "MongoDB Developer Course",
        courseUrl: "/database-courses/mongodb-developer-course",
      },
      {
        courseName: "Postgresql Training",
        courseUrl: "/database-courses/postgresql",
      },
      {
        courseName: "Neo4j Certification",
        courseUrl: "/database-courses/master-neo4j-graph-database",
      },
      {
        courseName: "Mariadb Course",
        courseUrl: "/database-courses/mariadb-for-developers",
      },
      {
        courseName: "Hbase Training",
        courseUrl: "/database-courses/hbase",
      },
      {
        courseName: "MongoDB Administrator Course",
        courseUrl: "/database-courses/mongodb-administrator",
      },
    ],
  },
  {
    id: "21",
    name: "Soft Skills Training",
    link: "/soft-skills-training",
    courses: [
      {
        courseName: "Conflict Management Training",
        courseUrl: "/soft-skills-training/conflict-management",
      },
      {
        courseName: "Communication Course",
        courseUrl: "/soft-skills-training/communication-training",
      },
      {
        courseName: "International Certificate In Advanced Leadership Skills",
        courseUrl:
          "/soft-skills-training/international-certificate-in-advanced-leadership-skills",
      },
      {
        courseName: "Soft Skills Training",
        courseUrl: "/soft-skills-training/soft-skills-communication",
      },
      {
        courseName: "Soft Skills for Corporate Career Growth",
        courseUrl:
          "/soft-skills-training/soft-skills-for-corporate-career-growth",
      },
      {
        courseName: "Soft Skills Leadership Training",
        courseUrl: "/soft-skills-training/soft-skills-leadership",
      },
      {
        courseName: "Building Team Trust Workshop",
        courseUrl: "/soft-skills-training/building-team-trust-workshop",
      },
    ],
  },
  {
    id: "22",
    name: "CompTIA",
    link: "/comptia-courses",
    courses: [
      {
        courseName: "CompTIA A+ Certification",
        courseUrl: "/comptia-courses/comptia-a-certification-training",
      },
      {
        courseName: "CompTIA Cloud Essentials Certification",
        courseUrl:
          "/comptia-courses/comptia-cloud-essentials-certification-training",
      },
      {
        courseName: "CompTIA Cloud+ Certification",
        courseUrl: "/comptia-courses/comptia-cloud-certification",
      },
      {
        courseName: "CompTIA Mobility+ Certification",
        courseUrl: "/comptia-courses/comptia-mobility-certification",
      },
      {
        courseName: "CompTIA Network+ Certification",
        courseUrl: "/comptia-courses/comptia-network-certification",
      },
      {
        courseName: "CompTIA Security+ Certification",
        courseUrl: "/comptia-courses/comptia-security-certification",
      },
      {
        courseName: "CompTIA Server+ Certification",
        courseUrl: "/comptia-courses/comptia-server-certification",
      },
      {
        courseName: "CompTIA Project+ Certification",
        courseUrl: "/comptia-courses/comptia-project-certification",
      },
    ],
  },
  {
    id: "23",
    name: "Other",
    link: "/other-courses",
    courses: [
      {
        courseName: "MS Excel 2010",
        courseUrl: "/other-courses/ms-excel-2010-training",
      },
      {
        courseName: "Advanced Excel 2013",
        courseUrl: "/other-courses/advanced-excel-2013-training",
      },
      {
        courseName: "IoT",
        courseUrl: "/other-courses/IoT",
      },
      {
        courseName: "Certified Supply Chain Professional",
        courseUrl: "/other-courses/certified-supply-chain-professional",
      },
      {
        courseName: "Software Estimation and Measurement Using IFPUG FPA",
        courseUrl:
          "/other-courses/software-estimation-and-measurement-using-ifpug-fpa",
      },
      {
        courseName:
          "Software Size Estimation and Measurement using IFPUG FPA & SNAP",
        courseUrl:
          "/other-courses/software-size-estimation-and-measurement-using-ifpug-fpa-and-snap",
      },
      {
        courseName:
          "Leading and Delivering World Class Product Development Course",
        courseUrl:
          "/other-courses/leading-and-delivering-world-class-product-management-training",
      },
      {
        courseName:
          "Product Management and Product Marketing for Telecoms IT and Software",
        courseUrl:
          "/other-courses/product-management-and-product-marketing-for-telecoms-it-and-software",
      },
      {
        courseName: "Foundation Certificate in Marketing",
        courseUrl:
          "/other-courses/foundation-certificate-in-marketing-training",
      },
      {
        courseName: "Flow Measurement and Custody Transfer Training Course",
        courseUrl:
          "/other-courses/flow-measurement-and-custody-transfer-training",
      },
    ],
  },
];

export default menuDetails;
