// import Link from "next/link";
import { useRouter } from "next/router";
import React, { MouseEventHandler } from "react";
// import { TrendingCoursesProps } from "./interface";

type Props = {
  changeSearchBarStatus: MouseEventHandler<HTMLDivElement> | any;
};

const HeaderSearchBar: React.FC<Props> = ({ changeSearchBarStatus }) => {
  const router = useRouter();

  const [query, setQuery] = React.useState<string>("");

  // Input onchange function
  const changeQuery = (value: string) => {
    setQuery(value);
  };

  // Checking key press
  const checkKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  // Search function
  const handleSearch = () => {
    if (query.trim().length) {
      changeSearchBarStatus();
      router.push(`/blog/search?q=${query.trim()}&sort_by=title`);
    }
  };

  return (
    <div>
      <div className="absolute left-0 top-0 px-2 flex justify-between items-center bg-white w-full h-14 border-b sm:px-7">
        {/* Search icon */}
        <div
          className="cursor-pointer py-1 px-2 rounded-full md:hover:bg-gray-100"
          onClick={handleSearch}
        >
          <i className="blog-icons icon-search-black inline-block"></i>
        </div>

        {/* Input box */}
        <div className="w-11/12">
          <input
            type="text"
            placeholder="What do you want to learn"
            className="border-none outline-none w-full text-sm text-gray-600 focus-visible:outline-none"
            value={query}
            onKeyPress={(event) => checkKey(event)}
            onChange={(event) => changeQuery(event.target.value)}
            autoFocus
          />
        </div>

        {/* Close icon */}
        <div
          className="cursor-pointer py-1 px-2 rounded-full md:hover:bg-gray-100"
          onClick={changeSearchBarStatus}
        >
          <i className="blog-icons icon-close inline-block"></i>
        </div>
      </div>
    </div>
  );
};

export default HeaderSearchBar;
