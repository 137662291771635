import React, { useState } from "react";
import { MenuItemsProps } from "../MenuItems/Interface";
import HeaderIconSmallScreen from "./HeaderIconSmallScreen";
import HeaderSearchBar from "./HeaderSearchBar";
import HeaderLeftSideBar from "./HeaderLeftSideBar";
import HeaderTopSideBar from "./HeaderTopSideBar";

type Props = {
  MenuItemsProps: MenuItemsProps;
  MegaMenuProps: any;
};
type HeaderProps = {
  courseID?: number;
};
const HeaderSmallScreen: React.FC<HeaderProps> = ({courseID}) => {
  
  const [isShowSideBar, setIsShowSideBar] = React.useState<any>(false);

  const [side, setSide] = useState<"top" | "left" | null>(null);

  const [isShowSideTopBar, setIsShowSideTopBar] =
    React.useState<boolean>(false);

  const [searchBar, setSearchBar] = React.useState<boolean>(false);

  const changeSideTopBarStatus: React.MouseEventHandler<HTMLDivElement> =
    () => {
      setSide("top");
      setIsShowSideTopBar(true);
    };

  const changeSideBarStatus: React.MouseEventHandler<HTMLDivElement> = () => {
    setSide("left");
    setIsShowSideBar(true);
  };

  const closeAllSideBars = () => {
    setSide(null);
    isShowSideBar && setIsShowSideBar(false);
    isShowSideTopBar && setIsShowSideTopBar(false);
  };

  const changeSearchBarStatus: React.MouseEventHandler<HTMLDivElement> = () => {
    setSearchBar(!searchBar);
  };

  return (
    <nav className="2xl:hidden">
      {/* Header */}
      <div className="flex justify-between items-center h-14">
        <div className="flex items-center gap-x-2">
          <div
            className="cursor-pointer rounded-full py-1 px-2 md:hover:bg-gray-100"
            onClick={changeSideBarStatus}
          >
            <i className="blog-icons icon-hamburg inline-block"></i>
          </div>

          <HeaderIconSmallScreen />
        </div>

        <div className="flex items-center gap-x-2">
          <div
            className="border border-gray-7 rounded w-16 h-7 flex items-center justify-center font-semibold text-xs leading-4 text-gray-15 cursor-pointer"
            onClick={changeSideTopBarStatus}
          >
            Courses
          </div>

          <div
            className="cursor-pointer rounded-full p-1 mt-6p px-2 md:hover:bg-gray-100"
            onClick={changeSearchBarStatus}
          >
            <i className="blog-icons icon-search-black inline-block"></i>
          </div>
        </div>
      </div>

      {/* Search bar */}
      {searchBar && (
        <HeaderSearchBar changeSearchBarStatus={changeSearchBarStatus} />
      )}

      {/* Left Side bar */}
      <HeaderLeftSideBar
        sideBar={isShowSideBar || isShowSideTopBar}
        side={side}
        closeAllSideBars={closeAllSideBars}
        courseID={courseID}
      />

      {/* Top Side bar */}
      <HeaderTopSideBar
        sideBar={isShowSideBar || isShowSideTopBar}
        side={side}
        closeAllSideBars={closeAllSideBars}
      />
    </nav>
  );
};

export default HeaderSmallScreen;
