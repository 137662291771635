import { ChevronDownIcon } from "@heroicons/react/solid";
import React from "react";
import { trackGa4Events } from "../../lib/events/ga4/tracker";

type Props = {
  leftBorder?: boolean;
  leftIcon?: any;
  title: string;
  icon?: any;
  icon2?: any;
  showBody: any;
  changeBodyStatus: any;
  sticky?: boolean;
  ga4EventKey?: any;
};

const Accordion: React.FC<Props> = ({
  title,
  icon,
  icon2,
  leftIcon,
  leftBorder,
  showBody,
  changeBodyStatus,
  sticky,
  children,
  ga4EventKey,
}) => {

  return (
    <div
      className={`${leftBorder && `border-l-2 ${showBody === title ? "border-pink-2" : "border-transparent"}`}`}
    >
      {/* Accordion Heading */}
      <div
        className={`border-b border-gray-9 flex justify-between items-center p-4  cursor-pointer ${
          sticky && "sticky top-0 bg-white"
        }`}
        onClick={() => {changeBodyStatus(title); trackGa4Events(ga4EventKey);}}
      >
        <div className={`flex text-sm font-semibold leading-5`}>
          {leftIcon && <div className="mr-1">{leftIcon}</div>}
          <div>{title}</div>
        </div>
        <div
          className={`${
            showBody === title && "rotate-180 text-pink-2"
          } transition-all duration-200 w-5`}
        >
          {(showBody === title && icon2) || icon || <ChevronDownIcon />}
        </div>
      </div>

      {/* Accordion Body */}
      <div
        className={`bg-gray-100 transition-all duration-500 overflow-hidden max-h-0 ${
          showBody === title && "max-h-10000"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
