import { FC, useState } from "react";
import { MenuItemsData, MenuItemsProps } from "./Interface";
import { ChevronRightIcon } from "@heroicons/react/solid";

const SubMenuItems: FC<MenuItemsProps> = ({ title, toShow, data }) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <div className="group relative">
      <div
        className={`flex justify-between items-center py-6p px-3 border-b border-gray-100  text-xs cursor-pointer hover:bg-gray-100 duration-[0.3s] ${
          showMore && "bg-gray-100"
        }`}
        onMouseEnter={() => setShowMore(true)}
        onMouseLeave={() => setShowMore(false)}
      >
        {title || "More "}
        <span className={`transition-all delay-75 ${showMore && "rotate-180"}`}>
          <ChevronRightIcon className="w-5 text-gray-600" />
        </span>
      </div>
      {showMore && (
        <div
          className="absolute top-0  shadow-md right-full z-50 flex-col hidden bg-white rounded-sm  w-52 group-hover:block duration-[0.3s]"
          onMouseEnter={() => setShowMore(true)}
          onMouseLeave={() => setShowMore(false)}
        >
          {data
            ?.slice(toShow)
            .map(({ ref, title, data }: MenuItemsData, index: number) => (
              <a href={ref} key={index}>
                <div className="py-2 px-3 border-b border-gray-100  text-xs cursor-pointer hover:bg-gray-100 duration-[0.3s]">
                  {title}
                </div>
              </a>
            ))}
        </div>
      )}
    </div>
  );
};

export default SubMenuItems;
