import Image from "next/image";
const EnquiryNumber = ({ phoneNumber }: any) => {
  return (
    <div className="bg-blue-18 md:hidden items-center justify-center flex gap-2 h-8">
      <p className="text-white text-sm font-medium">For enquiries call:</p>
      <a
        href={`tel:${phoneNumber}`}
        rel="nofollow"
        className="flex items-center gap-1 "
      >
        {/* <span className="!w-5 !h-5"> */}
          <Image
            className="w-5 h-5"
            src="https://d2o2utebsixu4k.cloudfront.net/phone_white.svg"
            width="20"
            height="20"
            alt="Phone"
          />
        {/* </span> */}

        <p className="text-white text-sm underline underline-offset-2 font-bold">
          {phoneNumber}
        </p>
      </a>
    </div>
  );
};

export default EnquiryNumber;
