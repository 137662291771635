import axios from 'axios';
import { camelCaseConvertor } from './camelCaseConvertor';

// getCityBySlug2
export async function getCityBySlug(slug: string) {
  const cityData = await axios.get(`kh/cities/`, {
    params: { slug: slug, status: 1},
    validateStatus: () => true,
  });

  if (cityData.data.result == false) return null;

  return cityData.data.result ? camelCaseConvertor(cityData.data.result[0]) : null;
}