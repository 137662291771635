import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { trackGa4Events } from "../../lib/events/ga4/tracker";

const HeaderIcon: React.FC = () => {
  const router = useRouter();

  const handleClick = (e: any) => {
    e.preventDefault();
    trackGa4Events("homepage_click_Khut_logo_header");
    window.location.href = "/";
  };

  return (
    // Header Image
    <a className="w-122 md:w-122 cursor-pointer" onClick={handleClick}>
      <Image
        src="/kh-desktop-new-logo.svg"
        alt="Knowledgehut Logo"
        width="122"
        height="44"
        layout="responsive"
        priority={true}
      />
    </a>
  );
};

export default HeaderIcon;
