import axios from 'axios';

// getCityBySlug
export async function getStateByName(name: string, city_name: string, timezone_name: string) {
  
  const cityData = await axios.get(`kh/states/`, {
    params: { name: name, city_name: city_name, timezone_name: timezone_name},
    validateStatus: () => true,
  });

  if (cityData.data.result == false) return null;
  const result: any = [];

  return cityData.data.result ? cityData.data.result[0] : null;
}
