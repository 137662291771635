import { FC, useEffect } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import strMobileImage from "../../public/Images/Mobile_march_4.svg";
import strDesktopImage from "../../public/Images/Web_march_4.svg";
import strIMobileImage from "../../public/Images/Mobile_march_4.svg";
import strIDesktopImage from "../../public/Images/Web_march_4.svg";

interface PostsProps {
  courseID: number;
  topBannerData?: any;
}

const TopBanner: FC<PostsProps> = ({ courseID, topBannerData }) => {
  const router = useRouter();

  if (!topBannerData) {
    return <></>;
  }
  let isShowTopBanner = false;
  let redirectingUrl = topBannerData?.data?.link;
  let altName = topBannerData?.data?.altname;

  let backgroundColor = topBannerData?.data?.backgroundcolor;
  let mobileImage =
    typeof topBannerData?.data?.mobileimage === "string"
      ? JSON.parse(topBannerData?.data?.mobileimage)
      : topBannerData?.data?.mobileimage;
  let desktopImage =
    typeof topBannerData?.data?.image === "string"
      ? JSON.parse(topBannerData?.data?.image)
      : topBannerData?.data?.image;

  const CommonComponent = () => (
    <>
      <div
        className={`hidden md:block relative  h-14`}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <Image
          src={`${process.env.NEXT_PUBLIC_AWS_URI}/${desktopImage[0].key}`}
          alt={altName}
          priority
          layout="fill"
          className="object-cover"
        />
      </div>

      <div
        className={`block relative md:hidden h-14`}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <Image
          src={`${process.env.NEXT_PUBLIC_AWS_URI}/${mobileImage[0].key}`}
          alt={`${altName}-mobile`}
          priority
          layout="fill"
        />
      </div>
    </>
  );

  return (
    <>
      {isShowTopBanner && (
        <>
          {redirectingUrl ? (
            <a href={redirectingUrl} target="_blank" rel="noopener noreferrer">
              <CommonComponent />
            </a>
          ) : (
            <CommonComponent />
          )}
        </>
      )}
    </>
  );
};

export default TopBanner;
