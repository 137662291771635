import { title } from "process";
import { MenuItemsData } from "./Interface";

export const EnterpriseData: Array<MenuItemsData> = [
  {
    title: "Learning Solutions",
    ref: "/corporate-training",
  },
  {
    title: "Agile Solutions",
    ref: "",
    data: [
      {
        title: "SAFe Implementation",
        ref: "/safe-transformation",
      },
      {
        title: "Business Agility",
        ref: "/enterprise/business-agility",
      },
      {
        title: "Value Stream Workshop/Optimization",
        ref: "/enterprise/value-stream-optimization",
      },
      {
        title: "DevOps Cultural Transformation",
        ref: "/enterprise/devops-cultural-transformation ",
      },
      {
        title: "Technology & Business Management",
        ref: "/enterprise/technology-and-business-management",
      },
      {
        title: "Lean Portfolio Management",
        ref: "/enterprise/lean-portfolio-management",
      },
    ],
  },
  {
    title: "Product Building",
    ref: "",
    data: [
      {
        title: "Product Coaching",
        ref: "/enterprise/product-coaching",
      },
      {
        title: "Design Thinking Workshops",
        ref: "/enterprise/design-thinking-workshops",
      },
      {
        title: "Project to Product (Culture Shift)",
        ref: "/enterprise/project-to-product-culture-shift",
      },
      {
        title: "Product Development Training",
        ref: "/enterprise/product-development-training",
      },
    ],
  },
];

export const BootcampsData: Array<MenuItemsData> = [
  {
    title: "Data Science Bootcamp",
    ref: "/data-science/data-science-bootcamp-training",
  },
  {
    title: "Full-Stack Developer Bootcamp",
    ref: "/web-development/fullstack-development-bootcamp-training",
  },
  {
    title: "UI/UX Design Bootcamp",
    ref: "/web-development/ui-ux-design-bootcamp-course",
  },
  {
    title: "Software Engineering Bootcamp",
    ref: "/web-development/software-engineer-bootcamp",
  },
  {
    title: "Software Engineer Bootcamp (with PMI)",
    ref: "/web-development/software-engineering-bootcamp-with-pmi",
  },
  {
    title: "DevOps Engineer Bootcamp (with PMI)",
    ref: "/devops/devops-engineering-bootcamp-with-pmi",
  },
  {
    title: "AI Engineer Bootcamp",
    ref: "/data-science/ai-engineer-bootcamp-course",
  },
  {
    title: "Data Engineer Bootcamp",
    ref: "/data-science/data-engineer-bootcamp-course",
  },
  {
    title: "Data Analyst Bootcamp",
    ref: "/data-science/data-analyst-bootcamp-course",
  },
  {
    title: "Cloud Engineer Bootcamp",
    ref: "/cloud-computing/cloud-engineer-bootcamp-training",
  },
  {
    title: "Cyber Security Bootcamp",
    ref: "/it-security/cyber-security-bootcamp",
  },
  {
    title: "Front-End Developer Bootcamp",
    ref: "/web-development/front-end-development-bootcamp-training",
  },
  {
    title: "Back End Engineer Bootcamp (Node JS)",
    ref: "/web-development/back-end-development-bootcamp-training",
  },
  {
    title: "Java Full Stack Developer Bootcamp",
    ref: "/web-development/fsd-java-stack-bootcamp-training",
  },
];

export const ResourcesData: Array<MenuItemsData> = [
  {
    title: "Tutorials",
    ref: "/tutorials",
  },
  {
    title: "Practise Tests",
    ref: "/practice-tests",
  },
  {
    title: "Interview Questions",
    ref: "/interview-questions",
  },
  {
    title: "Events",
    ref: "",
    data: [
      {
        title: "Master Classes",
        ref: "/master-classes",
      },
      {
        title: "Meetups",
        ref: "/meetups",
      },
      {
        title: "Counselling",
        ref: "/counselling",
      },
      {
        title: "Our Events - L3",
        ref: "/l3",
      },
    ],
  },
  {
    title: "High Paying IT Certifications",
    ref: "/blog/career/best-high-paying-business-certifications-and-it-certifications",
  },
  {
    title: "Scrum Master Certification Guide",
    ref: "/agile-management/scrum-master-certification-guide",
  },
  {
    title: "Course Info",
    ref: "/info",
  },
];

export const HelpData: Array<MenuItemsData> = [
  {
    title: "FAQ",
    ref: "/info",
  },
  {
    title: "Contact Us",
    ref: "/contact-us",
  },
];

// export const menuItemDetails: Array<MenuItemsData> = [
// 	{
// 		ref: '/blog/category/agile',
// 		title: 'Agile',
// 	},
// 	{
// 		ref: '/blog/category/data-science',
// 		title: 'Data Science',
// 	},
// 	{
// 		ref: '/blog/category/project-management',
// 		title: 'Project Mangement',
// 	},
// 	{
// 		ref: '/blog/category/programming',
// 		title: 'Programming',
// 	},
// 	{
// 		ref: '/blog/category/devops',
// 		title: 'DevOps',
// 	},
// 	{
// 		ref: '/blog/category/it-service-management',
// 		title: 'IT Service Management',
// 	},
// 	{
// 		ref: '/blog/category/information-technology',
// 		title: 'Information Technology',
// 	},
// 	{
// 		ref: '/blog/category/business-management',
// 		title: 'Business Management',
// 	},
// 	{
// 		ref: '/blog/category/big-data',
// 		title: 'Big Data',
// 	},
// 	{
// 		ref: '/blog/category/cloud-computing',
// 		title: 'Cloud Computing',
// 	},
// 	{
// 		ref: '/blog/category/learning',
// 		title: 'Learning',
// 	},
// 	{
// 		ref: '/blog/category/security',
// 		title: 'Security',
// 	},
// 	{
// 		ref: '/blog/category/web-development',
// 		title: 'Web Development',
// 	},
// 	{
// 		ref: '/blog/category/finance',
// 		title: 'Finance',
// 	},
// 	{
// 		ref: '/blog/category/digital-marketing',
// 		title: 'Digital Marketing',
// 	},
// 	{
// 		ref: '/blog/category/quality',
// 		title: 'Quality',
// 	},
// 	{
// 		ref: '/blog/category/business-intelligence-and-visualization',
// 		title: 'Business intelligence and Visualization',
// 	},
// 	{
// 		ref: '/blog/category/career',
// 		title: 'Career',
// 	},
// 	{
// 		ref: '/blog/category/journal',
// 		title: 'Journal',
// 	},
// 	{
// 		ref: '/blog/category/others',
// 		title: 'Others',
// 	},
// 	{
// 		ref: '/blog/category/software-testing',
// 		title: 'Software Testing',
// 	},
// 	{
// 		ref: '/blog/type/blogs',
// 		title: 'Blogs',
// 	},
// 	{
// 		ref: '/blog/type/infographics',
// 		title: 'Infographics',
// 	},
// 	{
// 		ref: '/blog/type/news',
// 		title: 'news',
// 	},
// 	{
// 		ref: '/blog/type/announcements',
// 		title: 'Announcements',
// 	},
// ];
