export const PAGE_TYPES = {
  HOME: "home_page",
  CATEGORY: "category_page",
  AUTHOR: "author_page",
  DETAILS: "detail_page",
  OTHERS: "other_page",
};

export const PAGE_CATEGORY = {
  BLOG_HOME: "blog_home",
  BLOG_DETAILS: "blog_details",
  BLOG_CATEGORY: "blog_category",
  BLOG_AUTHOR: "blog_author",
  BLOG_OTHERS: "blog_others",
};
