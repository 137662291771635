import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

const HeaderTopSideBarLogo: React.FC = () => {
  const router = useRouter();

  const handleClick = (e: any) => {
    e.preventDefault();
    window.location.href = "/";
  };

  return (
    // Header Image
    <a onClick={handleClick}>
      <Image
        src="/kh-desktop-new-logo.svg"
        alt="Knowledgehut Logo"
        width="86"
        height="31"
        priority={true}
      />
    </a>
  );
};

export default HeaderTopSideBarLogo;
